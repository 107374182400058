import { Entity } from '@camberi/firecms';
import { Button, TextField } from '@mui/material';
import { doc, getFirestore, increment, updateDoc } from 'firebase/firestore';
import { FC, useCallback, useState } from 'react';

import { ProductSchema } from '../schemas/product.schema';

interface Props {
  entity: Entity<ProductSchema>;
}

const StockField: FC<Props> = ({ entity }) => {
  const [value, setValue] = useState<number>(0);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const submit = useCallback(() => {
    if (!value) {
      setError(new Error('Value must be non zero.'));
      return;
    }

    const db = getFirestore();
    const productStatsRef = doc(db, entity.path, entity.id, 'private/stats');

    setSubmitting(true);

    updateDoc(productStatsRef, { stockGrams: increment(value) })
      .then(() => {
        setValue(0);
        setError(null);
      })
      .catch((err) => setError(err))
      .finally(() => {
        setSubmitting(false);
      });
  }, [entity.id, entity.path, value]);

  return (
    <div style={{ display: 'flex' }}>
      <TextField
        required
        error={!!error}
        disabled={submitting}
        label="Add Stock"
        value={isNaN(value) ? '' : value}
        onChange={(e) => {
          setValue(parseInt(e.target.value));
        }}
        helperText={error?.message}
        fullWidth
        variant={'filled'}
        type="number"
        InputLabelProps={{ shrink: true }}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
      />
      <Button style={{ marginLeft: 10 }} onClick={submit}>
        Add
      </Button>
    </div>
  );
};

export default StockField;

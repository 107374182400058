/* eslint-disable sort-keys-fix/sort-keys-fix */

import { buildEntityCallbacks, buildSchema } from '@camberi/firecms';
import {
  collection,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';

import buildSlug, { slugRe } from '../utils/buildSlug';
import { Product } from './product.schema';

export interface PresetBoxItem {
  quantity: number;
  productRef: DocumentReference;
}

export interface PresetItem {
  roasterId: string;
  productId: string;
  quantity: number;
}

export interface PresetBox {
  name: string;
  slug: string;
  setNumber: number;
  items: PresetItem[];
  image: string;
  published: boolean;
  boxItems: PresetBoxItem[];
}

export type PresetBoxSchema = Omit<PresetBox, 'boxItems'>;

export const presetBoxSchema = buildSchema<PresetBoxSchema>({
  name: 'Preset Box',
  defaultValues: { published: false },
  properties: {
    name: { dataType: 'string', title: 'Name', validation: { unique: true, required: true, trim: true } },
    slug: { dataType: 'string', title: 'Slug', validation: { unique: true, matches: slugRe, trim: true } },
    setNumber: {
      dataType: 'number',
      title: 'Set Number',
      validation: { integer: true, min: 1, max: 6, required: true },
    },
    image: () => ({
      config: {
        storageMeta: {
          acceptedFiles: ['image/*'],
          fileName: () => `image-${Date.now()}`,
          mediaType: 'image',
          metadata: {
            cacheControl: 'max-age=1000000',
          },
          storagePath: () => `presetBoxes`,
        },
      },
      dataType: 'string',
      title: 'Image',
    }),
    items: {
      title: 'Items',
      dataType: 'array',
      of: {
        dataType: 'map',
        properties: {
          roasterId: {
            dataType: 'string',
            title: 'Roaster ID',
            validation: { required: true },
          },
          productId: {
            dataType: 'string',
            title: 'Product ID',
            validation: { required: true },
          },
          quantity: {
            dataType: 'number',
            title: 'quantity',
            validation: { required: true, max: 4, min: 1, integer: true },
          },
        },
      },
    },
    published: { dataType: 'boolean', title: 'Published' },
  },
});

interface ProductDoc extends Product {
  ref: DocumentReference;
}

export const getProduct = async (roasterId: string, productId: string): Promise<ProductDoc | null> => {
  const db = getFirestore();
  const productRef = doc(db, `roasters/${roasterId}/products`, productId);
  const product = await getDoc(productRef);

  if (!product.exists()) return null;

  return { ...(product.data() as Product), ref: productRef };
};

const buildPresetBoxItems = async (schemaItems: PresetBoxSchema['items']): Promise<PresetBoxItem[] | undefined> => {
  const boxItems = await Promise.all(
    schemaItems.map(async (item) => ({
      quantity: item.quantity,
      product: await getProduct(item.roasterId, item.productId),
    })),
  );

  for (let i = 0; i < boxItems.length; i++) {
    if (!boxItems[i].product)
      throw new Error(`Preset Box Item #${i + 1} is an invalid object. Check the roaster or product ID.`);
    if (!boxItems[i].product?.forSale) throw new Error(`Preset Box Item #${i + 1} is not currently for sale.`);
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return boxItems.map(({ quantity, product }) => ({ quantity, productRef: product!.ref }));
};

/*
1 - 2 - 3 - 4 - 5 - 6
x -> y

2 -> 5
1 - 3 - 4 - 5 - 2 - 6
x < z <= y ==> -1

5 -> 2
1 - 5 - 2 - 3 - 4 - 6
x > z >= y ==> +1 
*/
const reorderSets = async (newNum: number, oldNum?: number, currentName?: string): Promise<number> => {
  const db = getFirestore();
  const presetBoxesRef = collection(db, 'presetBoxes');
  const snapshot = await getDocs(
    currentName ? query(presetBoxesRef, where('name', '!=', currentName)) : presetBoxesRef,
  );
  let orderedSets: { setNumber: number; ref: DocumentReference | null }[] = snapshot.docs.map((doc) => ({
    ref: doc.ref,
    setNumber: doc.data().setNumber,
  }));

  orderedSets = orderedSets.map(({ setNumber, ...set }) => {
    if (!oldNum) {
      if (setNumber >= newNum) return { ...set, setNumber: setNumber + 1 };
      else return { ...set, setNumber };
    } else if (oldNum < newNum && oldNum < setNumber && setNumber <= newNum)
      return { ...set, setNumber: setNumber - 1 };
    else if (newNum < oldNum && oldNum > setNumber && setNumber >= newNum) return { ...set, setNumber: setNumber + 1 };
    else return { ...set, setNumber };
  });
  orderedSets.push({ ref: null, setNumber: newNum });
  orderedSets.sort((a, b) => a.setNumber - b.setNumber);

  await Promise.all(orderedSets.map(({ ref }, idx) => (ref ? updateDoc(ref, { setNumber: idx + 1 }) : null)));

  const newSetNumber = orderedSets.findIndex((set) => set.ref === null);

  return newSetNumber === -1 ? 0 : newSetNumber + 1;
};

export const presetBoxCallbacks = buildEntityCallbacks<PresetBoxSchema>({
  onPreSave: async ({ values, previousValues }) => {
    let published = values.published;
    if (values.published) {
      const sum = values.items?.map((item) => item.quantity).reduce((a, b) => a + b, 0);

      if (sum !== 4) {
        if (previousValues?.published) published = false;
        else throw new Error('Box set cannot be published: Quantity of preset box items must add up to 4');
      }
    }

    const presetBox = {
      ...values,
      published,
      ...(values.items && { boxItems: await buildPresetBoxItems(values.items) }),
    };

    if (
      (!values.slug && !previousValues?.slug) ||
      (values.name !== previousValues?.name && values.slug === previousValues?.slug)
    )
      presetBox.slug = buildSlug(values.name);

    if (values.setNumber && values.setNumber !== previousValues?.setNumber)
      presetBox.setNumber =
        (await reorderSets(values.setNumber, previousValues?.setNumber, previousValues?.name)) || presetBox.setNumber;

    return presetBox;
  },
});

/* eslint-disable sort-keys-fix/sort-keys-fix */

import { buildSchema } from '@camberi/firecms';

export type OptionValue = boolean | string | number | (string | number)[];

export enum QuestionType {
  'default' = 'default',
  'choice' = 'choice',
  'rating' = 'rating',
}

export enum QuizIcon {
  '/img/icons/proficient.svg' = 'Proficient',
  '/img/icons/cheap-price.svg' = 'Cheap Price',
  '/img/icons/light-scale.svg' = 'Light Scale',
  '/img/icons/credit-card.svg' = 'Credit Card',
  '/img/icons/non-milk.svg' = 'Non Milk',
  '/img/icons/magnifier.svg' = 'Magnifier',
  '/img/icons/medium-scale.svg' = 'Medium Scale',
  '/img/icons/pour-over.svg' = 'Pour Over',
  '/img/icons/meditation.svg' = 'Meditation',
  '/img/icons/flower.svg' = 'Flower',
  '/img/icons/icon-right.svg' = 'Icon Right',
  '/img/icons/pending-icon.svg' = 'Pending Icon',
  '/img/icons/circle-arrow-left.svg' = 'Circle Arrow Left',
  '/img/icons/icon-down.png.svg' = 'Icon Down.png',
  '/img/icons/graph-bean.svg' = 'Graph Bean',
  '/img/icons/mashed.svg' = 'Mashed',
  '/img/icons/syrups.svg' = 'Syrups',
  '/img/icons/torches.svg' = 'Torches',
  '/img/icons/novice.svg' = 'Novice',
  '/img/icons/mixed.svg' = 'Mixed',
  '/img/icons/expert.svg' = 'Expert',
  '/img/icons/aero-press.svg' = 'Aero Press',
  '/img/icons/unmashed.svg' = 'Unmashed',
  '/img/icons/green-check.svg' = 'Green Check',
  '/img/icons/floral.svg' = 'Floral',
  '/img/icons/random.svg' = 'Random',
  '/img/icons/moka-pot.svg' = 'Moka Pot',
  '/img/icons/msg-bubble.svg' = 'Msg Bubble',
  '/img/icons/dark-scale.svg' = 'Dark Scale',
  '/img/icons/completed-icon.svg' = 'Completed Icon',
  '/img/icons/icon-left.svg' = 'Icon Left',
  '/img/icons/qr-code.svg' = 'Qr Code',
  '/img/icons/none.svg' = 'None',
  '/img/icons/milk.svg' = 'Milk',
  '/img/icons/sugar.svg' = 'Sugar',
  '/img/icons/stars.svg' = 'Stars',
  '/img/icons/caramel.svg' = 'Caramel',
  '/img/icons/cocoa.svg' = 'Cocoa',
  '/img/icons/truck-free.svg' = 'Truck Free',
  '/img/icons/coffee-maker.svg' = 'Coffee Maker',
  '/img/icons/average-price.svg' = 'Average Price',
  '/img/icons/user-profile.svg' = 'User Profile',
  '/img/icons/coffee-capsule.svg' = 'Coffee Capsule',
  '/img/icons/lemon.svg' = 'Lemon',
  '/img/icons/cold-brew.svg' = 'Cold Brew',
  '/img/icons/menu-icon.svg' = 'Menu Icon',
  '/img/icons/honey.svg' = 'Honey',
  '/img/icons/beginner.svg' = 'Beginner',
  '/img/icons/drip-beaker.svg' = 'Drip Beaker',
  '/img/icons/fruity.svg' = 'Fruity',
  '/img/icons/chemex.svg' = 'Chemex',
  '/img/icons/expensive-price.svg' = 'Expensive Price',
  '/img/icons/french-press.svg' = 'French Press',
  '/img/icons/espresso-machine.svg' = 'Espresso Machine',
  '/img/icons/herb.svg' = 'Herb',
  '/img/icons/dot-grid.svg' = 'Dot Grid',
  '/img/icons/light-medium-scale.svg' = 'Light Medium Scale',
  '/img/icons/medium-dark-scale.svg' = 'Medium Dark Scale',
}

export interface QuizQuestionOption {
  title: string;
  subtitle?: string;
  icon?: string;
  value: OptionValue;
}

export interface QuizQuestion {
  key: string;
  type: QuestionType;
  options?: QuizQuestionOption[];
  title?: string;
  subtitle?: string;
  max?: number;
  min?: number;
  prerequisites?: string[];
  uniqueFrom?: string[];
}

export interface QuizPage {
  name: string;
  description: {
    title: string;
    content: string;
  };
  questions: QuizQuestion[];
}

export interface Quiz {
  published: boolean;
  pages: QuizPage[];
}

export const quizSchema = buildSchema<Quiz>({
  name: 'Quiz',
  customId: true,
  properties: {
    published: { dataType: 'boolean', title: 'Publish' },
    // eslint-disable-next-line max-lines-per-function
    pages: ({ values }) => {
      const questionKeys = Array.from(
        new Set(
          values?.pages
            ?.map((page) => page?.questions || [])
            ?.flat()
            ?.map((question) => question?.key),
        ),
      ).filter(Boolean);

      return {
        dataType: 'array',
        title: 'Quiz Pages',
        validation: { required: true, min: 1 },
        of: {
          dataType: 'map',
          title: 'Page',
          properties: {
            name: { dataType: 'string', title: 'Page Name', validation: { required: true, trim: true } },
            description: {
              dataType: 'map',
              title: 'Page Description',
              properties: {
                title: { dataType: 'string', title: 'Title', validation: { trim: true, required: true } },
                content: {
                  dataType: 'string',
                  title: 'Content',
                  config: {},
                  columnWidth: 300,
                  validation: { trim: true },
                },
              },
            },
            questions: {
              dataType: 'array',
              title: 'Quiz Questions',
              validation: { required: true, min: 1 },
              of: {
                dataType: 'map',
                title: 'Question',
                properties: {
                  key: {
                    dataType: 'string',
                    validation: { required: true, trim: true },
                    title: 'Question Key',
                  },
                  type: {
                    dataType: 'string',
                    title: 'Type',
                    config: { enumValues: QuestionType },
                    validation: { required: true },
                  },
                  options: {
                    dataType: 'array',
                    title: 'Options',
                    of: {
                      dataType: 'map',
                      properties: {
                        title: {
                          dataType: 'string',
                          title: 'Title',
                          validation: { required: true, trim: true },
                        },
                        subtitle: {
                          dataType: 'string',
                          title: 'Subtitle',
                          validation: { trim: true },
                        },
                        value: {
                          dataType: 'string',
                          validation: { required: true, trim: true },
                          title: 'Value',
                        },
                      },
                    },
                  },
                  title: {
                    dataType: 'string',
                    title: 'Title',
                    validation: { trim: true },
                  },
                  subtitle: {
                    dataType: 'string',
                    title: 'Subtitle',
                    validation: { trim: true },
                  },
                  min: {
                    dataType: 'number',
                    title: 'Min',
                  },
                  max: {
                    dataType: 'number',
                    title: 'Max',
                  },
                  prerequisites: {
                    dataType: 'array',
                    title: 'Prerequisites',
                    of: {
                      dataType: 'string',
                      config: {
                        ...(questionKeys && { enumValues: Object.fromEntries(questionKeys.map((key) => [key, key])) }),
                      },
                    },
                  },
                  uniqueFrom: {
                    dataType: 'array',
                    title: 'Unique From',
                    of: {
                      dataType: 'string',
                      config: {
                        ...(questionKeys && { enumValues: Object.fromEntries(questionKeys.map((key) => [key, key])) }),
                      },
                    },
                  },
                },
              },
            },
          },
        },
      };
    },
  },
});

/* eslint-disable sort-keys-fix/sort-keys-fix */

import {
  AdditionalColumnDelegate,
  AsyncPreviewComponent,
  buildSchema,
  Entity,
  EntityReference,
  PropertyBuilder,
} from '@camberi/firecms';
import { doc, DocumentReference, getDoc, getFirestore } from 'firebase/firestore';

import CustomReferenceField from '../components/CustomReferenceField';
import { Addition, BrewMethod, Product } from './product.schema';

export enum OrderStatus {
  'Created' = 'Created',
  'Processed' = 'Processed',
  'Shipped' = 'Shipped',
  'Completed' = 'Completed',
}

export enum PaymentMethod {
  'Card' = 'Card',
  'QR' = 'QR',
}

export enum ExperienceLevel {
  'Beginner' = 'Beginner',
  'Novice' = 'Novice',
  'Proficient' = 'Proficient',
  'Expert' = 'Expert',
}

export enum QuizTasteNote {
  'Fruity & Floral' = 'Fruity & Floral',
  'Sweet & Sugary' = 'Sweet & Sugary',
  'Cocoa & Nutty' = 'Cocoa & Nutty',
  'Spice & Herb' = 'Spice & Herb',
  'Roasted' = 'Roasted',
  'Other' = 'Other',
}

export enum QuizRoastLevel {
  'Light' = 'Light',
  'Light-Medium' = 'Light-Medium',
  'Medium' = 'Medium',
  'Medium-Dark' = 'Medium-Dark',
  'Dark' = 'Dark',
}

export interface QuizAnswers {
  experienceLevel: ExperienceLevel;
  tasteQualities: {
    acidity: number;
    sweetness: number;
    body: number;
  };
  tasteNotes: QuizTasteNote[];
  brewMethod: BrewMethod;
  grounded: boolean;
  roastLevel: QuizRoastLevel;
  addition?: Addition;
  minPrice?: number;
  maxPrice?: number;
}

export interface QuizRec {
  productRef: EntityReference;
  score: number;
  match: number;
  description: string;
}

export interface QuizResult {
  answers: QuizAnswers;
  recs?: QuizRec[];
  timestamp?: Date;
  version?: string;
  rec1?: QuizRec;
  rec2?: QuizRec;
  rec3?: QuizRec;
  rec4?: QuizRec;
}

const buildProductProperty =
  (key: string, idx: number): PropertyBuilder<EntityReference, QuizResult> =>
  ({ values }) => {
    const productRef = values.recs?.[idx].productRef;

    return {
      dataType: 'reference',
      title: `Recommendation ${idx + 1}`,
      path: productRef?.path?.substring(0, productRef?.path?.lastIndexOf('/')) || '',
      config: {
        Field: CustomReferenceField,
        customProps: {
          customKey: key,
          customValue: new EntityReference(
            productRef?.path?.substring(productRef?.path?.lastIndexOf('/') + 1) || '',
            productRef?.path?.substring(0, productRef?.path?.lastIndexOf('/')) || '',
          ),
        },
      },
    };

    // return {
    //   dataType: 'map',
    //   properties: {
    //     productRef: {
    //       dataType: 'reference',
    //       title: `Recommendation ${idx + 1}`,
    //       path: productRef?.path?.substring(0, productRef?.path?.lastIndexOf('/')) || '',
    //       config: {
    //         Field: CustomReferenceField,
    //         customProps: {
    //           customKey: key,
    //           customValue: new EntityReference(
    //             productRef?.path?.substring(productRef?.path?.lastIndexOf('/') + 1) || '',
    //             productRef?.path?.substring(0, productRef?.path?.lastIndexOf('/')) || '',
    //           ),
    //         },
    //       },
    //     },
    //     score: { dataType: 'number', title: 'Score' },
    //     match: { dataType: 'number', title: 'Match' },
    //     desctiption: { dataType: 'string', title: 'Description' },
    //   },
    // };
  };

export const quizResultSchema = buildSchema<QuizResult>({
  name: 'Quiz Result',
  properties: {
    timestamp: { dataType: 'timestamp', title: 'Timestamp' },
    answers: {
      dataType: 'map',
      title: 'Quiz Answers',
      properties: {
        experienceLevel: {
          config: { enumValues: ExperienceLevel },
          dataType: 'string',
          title: 'Experience Level',
          validation: { required: true },
        },
        tasteQualities: {
          dataType: 'map',
          description: 'Acididty, Sweetness, and Body',
          properties: {
            acidity: { dataType: 'number', title: 'Acidity (1 - 10)', validation: { max: 10, min: 1, required: true } },
            body: { dataType: 'number', title: 'Body (1 - 10)', validation: { max: 10, min: 1, required: true } },
            sweetness: {
              dataType: 'number',
              title: 'Sweetness (1 - 10)',
              validation: { max: 10, min: 1, required: true },
            },
          },
          title: 'Taste Qualities',
          validation: { required: true },
        },
        tasteNotes: {
          dataType: 'array',
          of: {
            dataType: 'string',
            title: 'Components',
            config: { enumValues: QuizTasteNote },
            validation: { uniqueInArray: true },
          },
          validation: { required: true, min: 1, max: 3 },
          title: 'Taste Notes',
        },
        brewMethod: {
          dataType: 'string',
          title: 'Brew Method',
          config: { enumValues: BrewMethod },
        },
        grounded: {
          dataType: 'boolean',
          title: 'Grounded',
        },
        addition: {
          dataType: 'string',
          title: 'Additions',
          config: { enumValues: Addition },
        },
        roastLevel: {
          config: { enumValues: QuizRoastLevel },
          dataType: 'string',
          title: 'Roast Level',
          validation: { required: true },
        },
        minPrice: { dataType: 'number', title: 'Min Price' },
        maxPrice: { dataType: 'number', title: 'Max Price' },
      },
    },
    version: {
      dataType: 'string',
      title: 'Version',
    },
    ...Object.fromEntries(['rec1', 'rec2', 'rec3', 'rec4'].map((key, idx) => [key, buildProductProperty(key, idx)])),
  },
});

const getProducts = async ({ path, id }: Entity<QuizResult>): Promise<Product[]> => {
  const db = getFirestore();
  const orderRef = doc(db, path, id);
  const order = await getDoc(orderRef);

  if (!order.exists()) return [];

  const productRefs = order.data().productRefs as DocumentReference[];

  if (!productRefs?.length) return [];

  const products = await Promise.all(productRefs.map((ref) => getDoc(ref)));

  return products
    .map((product) => (product.exists() ? (product.data() as Product) : null))
    .filter(Boolean) as Product[];
};

export const productListColumnDelegate: AdditionalColumnDelegate<QuizResult> = {
  id: 'productList',
  title: 'Products',
  width: 500,
  builder: ({ entity }) => {
    return (
      <AsyncPreviewComponent
        builder={getProducts(entity).then((products) => (
          <div>
            <ol>
              {products.map((product) => (
                <li key={product.name}>
                  {product.roaster?.name} - {product.name}
                </li>
              ))}
            </ol>
          </div>
        ))}
      />
    );
  },
};

import { Entity, useDataSource, useSnackbarController } from '@camberi/firecms';
import { Box, Button, Container, Paper } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import ExportOrderModal from '../components/order-management/ExportOrderModal';
import OrderManagementTable from '../components/order-management/OrderManagementTable';
import SingleOrderManagement from '../components/order-management/SingleOrderManagement';
import useAsyncCallback from '../hooks/useAsyncCallback';
import { OrderSchema, orderSchema } from '../schemas/order.schema';

const OrderManagementView: FC = () => {
  const params = useParams();
  const snackbarController = useSnackbarController();
  const { fetchCollection } = useDataSource();
  const [open, setOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<Entity<OrderSchema> | null>(null);

  const [fetchOrders, { data: orders, error: ordersError }] = useAsyncCallback(() => {
    return fetchCollection<OrderSchema>({
      order: 'desc',
      orderBy: 'orderTimestamp',
      path: 'orders',
      schema: orderSchema,
    });
  }, [fetchCollection]);

  useEffect(() => {
    fetchOrders();
    // RUN ONCE
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ordersError) {
      console.error('Error fetching orders.', ordersError);
      snackbarController.open({ message: 'Error fetching orders.', type: 'error' });
    }
  }, [ordersError, snackbarController]);

  useEffect(() => {
    const selectedOrder = orders?.find((order) => order.id === params.orderId);

    setSelectedOrder(selectedOrder || null);
  }, [orders, params.orderId]);

  return (
    <Box>
      <Container maxWidth="lg" className="flex flex-col mt-8 space-y-8">
        <Paper className="p-4">
          <Button variant="contained" onClick={() => setOpen(true)}>
            Export Orders
          </Button>
        </Paper>
        <Paper className="p-4">
          <OrderManagementTable orders={orders} />
        </Paper>
        {selectedOrder && (
          <Paper className="p-4">
            <SingleOrderManagement order={selectedOrder} refresh={fetchOrders} />
          </Paper>
        )}
      </Container>
      <ExportOrderModal open={open} onClose={() => setOpen(false)} />
    </Box>
  );
};

export default OrderManagementView;

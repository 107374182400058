import { DateTimeField, FieldProps } from '@camberi/firecms';
import { doc, getDoc, getFirestore, Timestamp } from 'firebase/firestore';
import { FC, useEffect, useState } from 'react';

interface Props {
  path: string;
  entityId: string;
}

const RoastedDateField: FC<FieldProps<Date, Props>> = ({ customProps, context, setValue, ...props }) => {
  const [loaded, setLoaded] = useState(false);
  const { path } = customProps || {};
  const { entityId } = context || {};

  useEffect(() => {
    if (loaded) return;
    if (!path || !entityId) return;

    const db = getFirestore();
    const productStatsRef = doc(db, path, entityId, 'private/stats');
    getDoc(productStatsRef).then((snapshot) => {
      if (!snapshot.exists()) return;

      const { roastedDate } = snapshot.data() as { roastedDate?: Timestamp };

      if (roastedDate) setValue(roastedDate.toDate());
      setLoaded(true);
    });
  }, [path, entityId, setValue, loaded]);

  return <DateTimeField {...props} context={context} customProps={customProps} setValue={setValue} />;
};

export default RoastedDateField;

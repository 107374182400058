import { useDataSource } from '@camberi/firecms';
import { CircularProgress, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { useLocation } from 'react-router';

import Layout from '../components/roaster-management/Layout';
import ExportPage from '../components/roaster-management/pages/ExportPage';
import HomePage from '../components/roaster-management/pages/HomePage';
import MyOrdersPage from '../components/roaster-management/pages/MyOrdersPage';
import MyProductsPage from '../components/roaster-management/pages/MyProductsPage';
import MyShopPage from '../components/roaster-management/pages/MyShopPage';
import useAsyncCallback from '../hooks/useAsyncCallback';
import { roasterSchema } from '../schemas/roaster.schema';

export const pathPageMap: Record<string, string> = {
  deliveries: 'My Deliveries',
  export: 'Export Data',
  orders: 'My Orders',
  parcels: 'My Parcels',
  products: 'My Products',
  shop: 'My Shop',
};

const RoasterManagementView: FC = () => {
  const location = useLocation();
  const [, , roasterId, page] = location.pathname.split('/');
  const { fetchEntity } = useDataSource();

  const [fetchRoaster, { data: roaster, loading: roasterLoading, error: roasterError }] = useAsyncCallback(
    () => fetchEntity({ entityId: roasterId, path: 'roasters', schema: roasterSchema }),
    [fetchEntity],
  );

  useEffect(() => {
    if (roasterId) fetchRoaster();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roasterId]);

  useEffect(() => {
    if (roasterError) console.error('Error fetching roaster.', roasterError);
  }, [roasterError]);

  if (roasterLoading)
    return (
      <div>
        <CircularProgress />
      </div>
    );

  if (!roaster) return <Typography>Roaster Data Missing.</Typography>;

  const currentPage = pathPageMap[page] || 'Home';

  const generatePage = () => {
    switch (currentPage) {
      case 'Home':
        return <HomePage roaster={roaster} />;
      case 'My Shop':
        return <MyShopPage roaster={roaster} refreshRoaster={fetchRoaster} />;
      case 'My Products':
        return <MyProductsPage roaster={roaster} />;
      case 'My Orders':
        return <MyOrdersPage roaster={roaster} />;
      // case 'My Parcels':
      //   return <MyParcelsPage roaster={roaster} />;
      // case 'My Deliveries':
      //   return <MyDeliveriesPage roaster={roaster} />;
      case 'Export Data':
        return <ExportPage />;
      default:
        return 'Error: 404';
    }
  };

  return <Layout currentPage={currentPage}>{generatePage()}</Layout>;
};

export default RoasterManagementView;

import { Typography } from '@mui/material';
import { FC } from 'react';

export const InputLabel: FC<{ text: string; isRequired?: boolean }> = ({ text, isRequired = false }) => {
  return (
    <Typography variant="body1" gutterBottom className="text-gray-500">
      {text} {isRequired && <span className="text-red-600">&nbsp;*</span>}
    </Typography>
  );
};

export default InputLabel;

import { Cookies } from 'react-cookie';

import { FIREBASE_PROJECT_ID, GCLOUD_PROJECT, isDev, isLocal } from '../config';

interface ApiOptions extends Omit<RequestInit, 'body'> {
  body?: RequestInit['body'] | Record<string, unknown>;
}

const endpoint = isLocal
  ? `http://127.0.0.1:5001/${GCLOUD_PROJECT}/asia-east2/rest`
  : `https://asia-east2-${FIREBASE_PROJECT_ID}.cloudfunctions.net/rest`;

const api = async <T = unknown>(url: string, optionsArg: ApiOptions = {}, json = true): Promise<T> => {
  const { body, ...options } = optionsArg;
  const token = new Cookies().get('token');

  try {
    const response = await fetch(`${endpoint}/${url}`, {
      method: 'GET',
      ...options,
      ...(body && {
        body: json && typeof body === 'object' ? JSON.stringify(body) : (body as RequestInit['body']),
      }),
      headers: {
        ...(Boolean(token) && { authentication: `Bearer ${token}` }),
        ...(json && { 'content-type': 'application/json' }),
        ...options?.headers,
      },
    });

    if (!response.status.toString().startsWith('2'))
      throw new Error(`${response.status} - ${response.statusText}: ${await response.text()}`);

    if (response.headers.get('content-type') === 'application/pdf') return (await response.blob()) as unknown as T;

    const data = await response.text();

    try {
      return JSON.parse(data);
    } catch (error) {
      return data as unknown as T;
    }
  } catch (error) {
    if (isDev) console.error(error);

    throw error;
  }
};

export default api;

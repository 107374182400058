import { FC, useState } from 'react';

interface IProps {
  menuItems: {
    id: string;
    label: string;
  }[];
  classNames?: string;
  step?: number
}

export const FormSidebar: FC<IProps> = ({ menuItems, classNames, step = 0 }) => {
  const [activeLink, setActiveLink] = useState(menuItems[step].id);

  return (
    <aside className={classNames}>
      <div className="w-[200px]">
        {menuItems.map((item) => (
          <div key={item.id} className="h-10 flex">
            <a
              href={`#${item.id}`}
              className={`no-underline text-gray-700 leading-10 pl-[10px] ${item.id == activeLink && 'font-bold'}`}
              style={{
                borderLeft: item.id == activeLink ? '4px solid #004AAA' : '4px solid #ffffff',
              }}
              onClick={() => setActiveLink(item.id)}
            >
              {item.label}
            </a>
          </div>
        ))}
      </div>
    </aside>
  );
};

export default FormSidebar;

import { useStorageSource } from '@camberi/firecms';
import { Button } from '@mui/material';
import { styled } from '@mui/styles';
import { FC } from 'react';

const IMAGE_UPLOADER = {
  ADD_IMAGE: 'เพิ่มรูปสินค้า',
};

const Input = styled('input')({
  display: 'none',
});

interface IProps {
  imageUrl?: string;
  fileName: string;
  uploadPath: string;
  onUploaded: (path: string) => void;
}

export const ImageUploader: FC<IProps> = ({ imageUrl, fileName, uploadPath, onUploaded }) => {
  const { uploadFile } = useStorageSource();

  const handleOnUploadImage = async (event: { target: { files: FileList | null } }) => {
    if (event.target.files?.length) {
      const file = event.target.files[0];
      const { path } = await uploadFile({
        file,
        fileName,
        path: `${uploadPath}/images`,
      });
      onUploaded(path);
    }
  };

  return (
    <div className="pt-9 w-[104px]">
      <label htmlFor="contained-button-file">
        <Input accept="image/*" id="contained-button-file" type="file" onChange={handleOnUploadImage} />
        {imageUrl ? (
          <div className="bg-[#E7EBEE] p-[1px] cursor-pointer rounded-sm">
            <div className="bg-white m-0 rounded-sm">
              <img width="100%" src={imageUrl} />
            </div>
          </div>
        ) : (
          <Button
            className="h-[104px] bg-[#F4F4F4] border-dashed border border-[#B6C0CB] text-[#60778E] text-center shadow-none"
            variant="contained"
            size="small"
            fullWidth={true}
            component="div"
          >
            + <br /> {IMAGE_UPLOADER.ADD_IMAGE}
          </Button>
        )}
      </label>
    </div>
  );
};

export default ImageUploader;

import { getAuth } from 'firebase/auth';
import ms from 'ms';
import { FC, useEffect } from 'react';
import { useCookies } from 'react-cookie';

import { isDev } from '../config';

const SessionProvider: FC = ({ children }) => {
  const [, setCookie, removeCookie] = useCookies(['token']);

  useEffect(() => {
    const unsubscribe = getAuth().onIdTokenChanged(
      (authUser) => {
        if (authUser) {
          authUser.getIdTokenResult().then((result) => {
            setCookie('token', result.token, { maxAge: ms('1h') / 1000 });
          });
        } else {
          removeCookie('token');
        }
      },
      (error) => {
        removeCookie('token');

        if (isDev) console.error('Auth Id Token Subscription Error', error);
      },
    );

    return unsubscribe;
  }, [removeCookie, setCookie]);

  return <>{children}</>;
};

export default SessionProvider;

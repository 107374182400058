export const PRODUCT_LIST = {
  DUPLICATE: 'Duplicate',
  EDIT: 'Edit',
};

export const PRODUCT_FORM = {
  ADDITIONS: {
    ID: 'additions',
    LABEL: 'เมล็ดกาแฟนี้เหมาะกับการชงกับอะไร (Addition)',
    OPTIONS: ['Black', 'Milk or Cream', 'Flavoured creamers or Syrup', 'Non-dairy milk', 'Sugar or Sweetener', 'Other'],
  },
  ADD_ENTRY: 'เพิ่มรายการ',
  ADD_OPTION: 'เพิ่มตัวเลือก',
  ALTITUDE_METERS: {
    ID: 'altitudeMeters',
    LABEL: 'ระดับความสูง',
  },
  AWARDS: {
    ID: 'awards',
    LABEL: 'ข้อมูลรางวัล',
  },
  BASIC_INFO: 'ข้อมูลทั่วไป',
  BREW_METHODS: {
    ID: 'brewMethods',
    LABEL: 'เมล็ดกาแฟชงกับเครื่องประเภทไหน (Brew Method)',
    OPTIONS: [
      'Coffee Maker',
      'French Press',
      'Pour Over',
      'Chemex',
      'Aeropress',
      'Cold Brew',
      'Moka Pot & Percolator',
      'Espresso Machine',
      'Refillable Capsule',
    ],
  },
  DESCRIPTION: {
    ID: 'description',
    LABEL: 'รายละเอียดสินค้า',
  },
  GROUNDABLE: {
    ID: 'groundable',
    LABEL: {
      GROUNDABLE: 'มีบริการบด',
      UNGROUNDABLE: 'ไม่บด',
    },
    NAME: 'groundable',
  },
  NAME: {
    ID: 'name',
    LABEL: 'ชื่อสินค้า',
  },
  ORIGINS: {
    CITY: {
      LABEL: 'City',
    },
    COUNTRY: {
      LABEL: 'Country',
    },
    ID: 'origins',
    TITLE: 'ต้นกำเนิด',
  },
  PROCESS: {
    LABEL: 'วิธีการผลิต',
    NAME: 'process',
    OPTIONS: ['Anaerobic', 'Barrel Aged', 'Enzyme', 'Experimental', 'Honey', 'Natural / Dry', 'Washed', 'Other'],
  },
  PRODUCT_ATTRIBUTES: 'คุณลักษณะของสินค้า',
  REMOVE_ENTRY: 'ลบ',
  ROASTING_NOTE: 'Roasting’s Note',
  ROAST_LEVEL: {
    LABEL: 'ระดับการคั่ว',
    NAME: 'roastLevel',
    OPTIONS: ['Cinnamon', 'City', 'Dark', 'French', 'Full City', 'High', 'Italian', 'Light', 'Medium'],
  },
  SELLING_INFO: {
    QUANTITY_OPTIONS: {
      ID: 'quantityOptions',
      PRICE: {
        LABEL: 'ราคา (บาท)',
      },
      SIZE: {
        LABEL: 'ขนาด, น้ำหนัก',
      },
      STOCK: {
        LABEL: 'จำนวน',
      },
    },
    TITLE: 'ข้อมูลการขาย',
  },
  TASTE_DESC: {
    ID: 'tasteDescription',
    LABEL: 'รายละเอียด Taste note ',
  },
  TASTE_NOTES: {
    ID: 'tasteNotes',
    LABEL: 'เลือก taste note',
    NAME: 'tasteNotes',
    OPTIONS: [
      'Chemical',
      'Chocolate',
      'Citrus',
      'Earthy',
      'Florals',
      'Nut',
      'Ripe Fruit',
      'Roasty',
      'Savory',
      'Spicy',
      'Sugary',
      'Tea',
      'Vanilla',
      'Other',
    ],
    TITLES: {
      PRIMARY: 'Taste note หลัก',
      SECONDARY: 'Taste note รอง',
    },
  },
  TASTE_QUALITIES: {
    ACIDITY: { ID: 'tasteQualities.acidity', LABEL: 'ระดับความเปรี้ยว', NAME: 'acidity' },
    BODY: { ID: 'tasteQualities.body', LABEL: 'ระดับความเข้ม', NAME: 'body' },
    ID: 'tasteQualities',
    LEVEL_OPTIONS: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    SWEETNESS: { ID: 'tasteQualities.sweetness', LABEL: 'ระดับความหวาน', NAME: 'sweetness' },
  },
  VARIETIES: {
    ID: 'varieties',
    LABEL: 'สายพันธุ์',
    OPTIONS: [
      'Bourbon',
      'Catuai',
      'Caturra',
      'Geisha, Gesha',
      'Heirloom',
      'Kent',
      'Maragogype',
      'Mundo Novo',
      'Pacamara',
      'Pacas',
      'S795',
      'SL-28',
      'SL-34',
      'Typica',
      'Villa Sarchi',
    ],
  },
};

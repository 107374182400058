/* eslint-disable sort-keys-fix/sort-keys-fix */

import { AdditionalColumnDelegate, buildSchema, Entity, EntityReference } from '@camberi/firecms';
import { Button } from '@mui/material';
import { doc, DocumentData, DocumentReference, getDoc, getFirestore } from 'firebase/firestore';
import { Link } from 'react-router-dom';

import { Product, ProductStats } from './product.schema';
import { ShippingAddress, User } from './user.schema';

export const SAMPLE_ITEMS = ['SampleBox', 'GiftBox'];

export enum OrderStatus {
  'Processed' = 'Processed',
  'Shipped' = 'Shipped',
  'Completed' = 'Completed',
}

export enum PaymentMethod {
  'Card' = 'Card',
  'QR' = 'QR',
}

interface OrderMetadata extends Record<string, unknown> {
  quizResultId?: string;
  items?: { path: string; quantity: number }[];
  grounded?: boolean;
}

export enum OrderItemType {
  'Product' = 'Product',
  'SampleBox' = 'SampleBox',
  'GiftBox' = 'GiftBox',
}

export interface OrderItem {
  uid: string;
  type: OrderItemType;
  name: string;
  image: string;
  quantity: number;
  productRefs: EntityReference[];
  unitPrice: number;
  products: {
    ref: EntityReference;
    name: string;
    image: string;
  }[];
  metadata?: Record<string, unknown>;
}

export interface OrderTicket {
  items: OrderItem[];
  shippedTimestamp?: Date;
  shippingCourier?: string;
  shipmentTrackingNumber?: string;
  deliveryComplete?: boolean;
}

export interface Order {
  tickets: OrderTicket[];
  status: OrderStatus;
  orderTimestamp: Date;
  userRef: EntityReference;
  user: {
    firstName: User['firstName'];
    lastName: User['lastName'];
    email: User['email'];
  };
  orderHoldRef: EntityReference;
  shippingAddress: ShippingAddress;
  subtotal: number;
  vat: number;
  discount: number;
  shipping: number;
  total: number;
  paymentMethod: PaymentMethod;
  paymentRef: EntityReference;
  paidTimestamp: Date;
  metadata?: OrderMetadata;
}

export type OrderSchema = Omit<Order, 'user' | 'orderHoldRef' | 'tickets'>;

export const orderSchema = buildSchema<OrderSchema>({
  name: 'Order',
  properties: {
    status: { dataType: 'string', title: 'Status', config: { enumValues: OrderStatus } },
    orderTimestamp: { dataType: 'timestamp', title: 'Order Timestamp' },
    userRef: { dataType: 'reference', path: 'users', title: 'User Reference' },
    shippingAddress: {
      dataType: 'map',
      properties: {
        title: { dataType: 'string', title: 'Title' },
        firstName: { dataType: 'string', title: 'First Name' },
        lastName: { dataType: 'string', title: 'Last Name' },
        address: { dataType: 'string', title: 'Address' },
        subdistrict: { dataType: 'string', title: 'Sub District' },
        district: { dataType: 'string', title: 'District' },
        province: { dataType: 'string', title: 'Province' },
        zipCode: { dataType: 'string', title: 'Zip Code' },
        country: { dataType: 'string', title: 'Country' },
        phoneNumber: { dataType: 'string', title: 'Phone Number' },
      },
      title: 'Shipping Address',
    },
    subtotal: { dataType: 'number', title: 'Subtotal' },
    vat: { dataType: 'number', title: 'Vat' },
    discount: { dataType: 'number', title: 'Discount' },
    shipping: { dataType: 'number', title: 'Shipping' },
    total: { dataType: 'number', title: 'Total' },
    paymentMethod: { dataType: 'string', title: 'Payment Method', config: { enumValues: PaymentMethod } },
    paymentRef: ({ values }) => {
      return {
        dataType: 'reference',
        title: 'Payment Reference',
        path: `${values.userRef?.path}/${values.userRef?.id}/payments`,
        previewProperties: ['status', 'paidAt', 'omiseCardId'],
      };
    },
    paidTimestamp: { dataType: 'timestamp', title: 'Paid Timestamp' },
  },
});

export type OrderProduct = Product & { ref: DocumentReference<DocumentData>; stats?: ProductStats };

export const getOrderProducts = async ({ path, id }: Entity<Order>, includeStats = false): Promise<OrderProduct[]> => {
  const db = getFirestore();
  const orderRef = doc(db, path, id);
  const order = await getDoc(orderRef);

  if (!order.exists()) return [];

  const productRefs = order.data().productRefs as DocumentReference[];

  if (!productRefs?.length) return [];

  const data = await Promise.all(
    productRefs.map((ref) =>
      Promise.all([getDoc(ref), includeStats ? getDoc(doc(db, ref.path, 'private/stats')) : null]),
    ),
  );

  const products = data
    .map(([product, stats]) =>
      product.exists()
        ? {
            ...(product.data() as Product),
            ref: product.ref,
            ...(stats?.exists() && { stats: stats.data() as ProductStats }),
          }
        : null,
    )
    .filter(Boolean) as OrderProduct[];

  return products;
};

export const orderManagementColumnDelegate: AdditionalColumnDelegate<OrderSchema> = {
  id: 'management',
  title: 'Manage Order',
  builder: ({ entity }) => (
    <Link to={`/order-management/${entity.id}`}>
      <Button>Manage</Button>
    </Link>
  ),
};

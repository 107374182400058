import { useCallback, useState } from 'react';

interface Result<T> {
  data?: T;
  error?: Error;
  loading: boolean;
  refetch?: () => void;
}

type AsyncCallback<T> = (...args: unknown[]) => Promise<T>;

const useAsyncCallback = <T>(
  asyncCallback: AsyncCallback<T>,
  dependencies: unknown[] = [],
): [() => void, Result<T>] => {
  const defaultState: Result<T> = { loading: false };
  const [result, setResult] = useState(defaultState);
  const callback = useCallback(
    async (...args) => {
      setResult({ loading: true });
      try {
        setResult({
          data: await asyncCallback(...args),
          loading: false,
        });
      } catch (error) {
        setResult({
          error: error as Error,
          loading: false,
          refetch: () => callback(...args),
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...dependencies, asyncCallback],
  );

  return [callback, result];
};

export default useAsyncCallback;

import { Button, Paper, Typography } from '@mui/material';
import { FC } from 'react';

export const FORM_FOOTER = {
  CLOSE: 'ยกเลิก',
  PUBLISH: {
    CONFIRM: 'ยืนยัน',
    SAVE_AND_PUBLISH: 'บันทึกและเผยแพร่',
  },
  SAVE_AS_DRAFT: 'บันทึกและซ่อน',
};
interface IProps {
  onPublish: () => void;
  onSaveAsDraft?: () => void;
  onClose: () => void;
  publishMsg?: string;
  hideSaveDraft?: boolean;
}

export const FormFooter: FC<IProps> = ({ onPublish, onSaveAsDraft, onClose, hideSaveDraft, publishMsg }) => (
  <Paper className="w-full absolute bottom-0 shadow-2xl">
    <div className="w-[616px] mx-auto flex justify-between">
      <div className="flex justify-between py-[14px]">
        <Button onClick={onClose} variant="text" size="large" className="rounded-full">
          {FORM_FOOTER.CLOSE}
        </Button>
      </div>
      <div className="flex justify-between py-[14px]">
        {!hideSaveDraft && (
          <Button
            onClick={onSaveAsDraft}
            className="font-normal text-base rounded-full normal-case mr-6"
            variant="outlined"
            color="info"
            size="large"
          >
            <Typography>{FORM_FOOTER.SAVE_AS_DRAFT}</Typography>
          </Button>
        )}
        <Button
          onClick={onPublish}
          className="bg-blue-500 font-normal text-base rounded-full normal-case"
          variant="contained"
          size="large"
        >
          <Typography>{publishMsg || FORM_FOOTER.PUBLISH.SAVE_AND_PUBLISH}</Typography>
        </Button>
      </div>
    </div>
  </Paper>
);

export default FormFooter;

import { collection, getDocs, getFirestore, onSnapshot, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';

import { OrderDocument } from '../interfaces';
import { parseDocument } from '../utils/firestore';

const getOrdersQuery = (roasterId: string) => {
  const db = getFirestore();
  const ordersRef = collection(db, 'orders');
  return query(ordersRef, where('metadata.roasterIds', 'array-contains', roasterId));
};

export const getRoasterOrders = async (roasterId: string) => {
  const ordersQuery = getOrdersQuery(roasterId);
  const snapshot = await getDocs(ordersQuery);

  return snapshot.docs.map((doc) => parseDocument(doc));
};

/**
 * Example hook to get data once
 *
 * useAsyncEffect(() => (roasterId ? getRoasterOrders(roasterId) : undefined), [roasterId]);
 */

const useRoasterOrders = (
  roasterId?: string,
): { orders: OrderDocument[] | null; loading: boolean; error: Error | null } => {
  const [orders, setOrders] = useState<OrderDocument[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!roasterId) {
      setOrders(null);
      setLoading(false);
      setError(null);
      return;
    }

    const unsubscribe = onSnapshot(
      getOrdersQuery(roasterId),
      (snapshot) => {
        const docs = snapshot.docs.map((doc) => {
          return { ...(doc.data() as OrderDocument), id: doc.id };
        });

        setOrders(docs);
      },
      (firestoreError) => {
        setError(firestoreError);
      },
    );

    return unsubscribe;
  }, [roasterId]);

  return { error, loading, orders };
};

export default useRoasterOrders;

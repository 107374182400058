/* eslint-disable sort-keys-fix/sort-keys-fix */

import { buildSchema } from '@camberi/firecms';

export interface ShippingAddress {
  title?: string;
  firstName: string;
  lastName: string;
  address: string;
  subdistrict: string;
  district: string;
  province: string;
  zipCode: string;
  country: string;
  phoneNumber: string;
}

export interface User {
  firstName: string;
  lastName: string;
  email: string;
  shippingAddresses?: ShippingAddress[];
  omiseCustomerId: string;
  imageUrl: string;
  title: string;
  introduction: string;
  linkedIn: string;
  facebook: string;
  twitter: string;
}

export const userSchema = buildSchema<User>({
  name: 'User',
  properties: {
    firstName: { dataType: 'string', title: 'First Name' },
    lastName: { dataType: 'string', title: 'Last Name' },
    email: { dataType: 'string', title: 'Email' },
    title: { dataType: 'string', title: 'Title' },
    introduction: { dataType: 'string', title: 'Introduction' },
    linkedIn: { dataType: 'string', title: 'linkedIn' },
    facebook: { dataType: 'string', title: 'facebook' },
    twitter: { dataType: 'string', title: 'twitter' },
    shippingAddresses: {
      dataType: 'array',
      of: {
        dataType: 'map',
        properties: {
          title: { dataType: 'string', title: 'Title' },
          firstName: { dataType: 'string', title: 'First Name' },
          lastName: { dataType: 'string', title: 'Last Name' },
          address: { dataType: 'string', title: 'Address' },
          subdistrict: { dataType: 'string', title: 'Sub District' },
          district: { dataType: 'string', title: 'District' },
          province: { dataType: 'string', title: 'Province' },
          zipCode: { dataType: 'string', title: 'Zip Code' },
          country: { dataType: 'string', title: 'Country' },
          phoneNumber: { dataType: 'string', title: 'Phone Number' },
        },
      },
      title: 'Shipping Addresses',
    },
    omiseCustomerId: { dataType: 'string', title: 'Omise Customer ID' },
    imageUrl: {
      dataType: 'string',
      title: 'Image URL',
      config: {
        storageMeta: {
          acceptedFiles: ['image/*'],
          fileName: () => `user-${Date.now()}`,
          mediaType: 'image',
          metadata: {
            cacheControl: 'max-age=1000000',
          },
          storagePath: 'users',
        },
      },
    },
  },
});

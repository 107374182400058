import { Entity } from '@camberi/firecms';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import ms from 'ms';
import { FC, ReactElement, useEffect, useMemo } from 'react';

import useRoasterOrders from '../../../hooks/useRoasterOrders';
import { OrderStatus } from '../../../schemas/order.schema';
import { Roaster } from '../../../schemas/roaster.schema';

const StatCard: FC<{ bgColor: string; title: string; subtitle: string; icon: ReactElement<string, string> }> = ({
  bgColor,
  title,
  subtitle,
  icon,
}) => {
  return (
    <div className={`w-[240px] h-[100px] text-white px-6 py-2 m-4 overflow-hidden relative ${bgColor}`}>
      <div className="m-0 text-[44px] leading-[54px] font-bold">{title}</div>
      <div className=" text-[22px] font-bold">{subtitle}</div>
      {icon}
    </div>
  );
};

interface Props {
  roaster: Entity<Roaster>;
}

const HomePage: FC<Props> = ({ roaster }) => {
  const { orders, loading: ordersLoading, error: ordersError } = useRoasterOrders(roaster?.id);
  const lateOrders = useMemo(() => {
    if (ordersLoading) return 0;
    if (!orders) return 0;

    return orders.reduce((acc, order) => {
      if (order.status === OrderStatus.Processed) {
        const orderDate = order.orderTimestamp;
        const orderDateTime = new Date(orderDate);
        const yesterday = new Date(new Date().getTime() - ms('1d'));

        if (orderDateTime < yesterday) acc++;
      }
      return acc;
    }, 0);
  }, [orders, ordersLoading]);
  const completedOrders = useMemo(() => {
    if (ordersLoading) return 0;
    if (!orders) return 0;

    return orders.reduce((acc, order) => {
      if (order.status === OrderStatus.Completed) acc++;

      return acc;
    }, 0);
  }, [orders, ordersLoading]);

  useEffect(() => {
    if (ordersError) console.error('Error fetching orders.', ordersError);
  }, [ordersError, orders]);

  return (
    <div className="bg-gray-1 w-full pt-12 pl-14 pr-[67px]">
      <h1 className="font-bold text-[32px] text-gray-700 m-0">Welcome, {roaster?.values.name}</h1>
      <div className="min-h-[468px] mt-8 py-9 px-16 bg-white shadow-xs flex">
        <StatCard
          title={`${lateOrders}`}
          subtitle="ล่าช้า"
          bgColor="bg-[#FFA8C7]"
          icon={<ErrorOutlineIcon fontSize="large" className="absolute top-1 -right-12 text-[#E8014C] text-[124px]" />}
        />
        <StatCard
          title={`${completedOrders}`}
          subtitle="ดำเนินการแล้ว"
          bgColor="bg-[#4A7FF0]"
          icon={
            <ReceiptOutlinedIcon fontSize="large" className="absolute top-1 -right-12 text-[#C4D6FC] text-[124px]" />
          }
        />
      </div>
    </div>
  );
};

export default HomePage;

import { EntityReference, FieldProps, ReferenceField } from '@camberi/firecms';
import { FC } from 'react';

interface Props {
  customKey: string;
  customValue: EntityReference;
}

const CustomReferenceField: FC<FieldProps<EntityReference, Props>> = ({ customProps, property, context, ...props }) => {
  return (
    <ReferenceField
      {...props}
      property={property}
      customProps={customProps}
      value={customProps.customValue}
      initialValue={customProps.customValue}
      context={{ ...context, values: { ...context.values, [customProps.customKey]: customProps.customValue } }}
    />
  );
};

export default CustomReferenceField;

/* eslint-disable sort-keys-fix/sort-keys-fix */

import { buildSchema, EntityReference } from '@camberi/firecms';

export interface Payment {
  amount: number;
  currency: string;
  omiseCardId?: string;
  paid: boolean;
  paidAt: Date;
  status: string;
  description?: string;
  metadata?: Record<string, string>;
  failureCode?: string;
  failureMessage?: string;
  orderRef: EntityReference;
  qrPaymentUri?: string;
}

export const paymentSchema = buildSchema<Payment>({
  name: 'Payment',
  properties: {
    orderRef: { title: 'Order', dataType: 'reference', path: 'orders' },
    amount: { title: 'Amount', dataType: 'number' },
    status: { title: 'Status', dataType: 'string' },
    paid: { title: 'Paid', dataType: 'boolean' },
    paidAt: { title: 'Paid At', dataType: 'timestamp' },
    omiseCardId: { title: 'Omise Card ID', dataType: 'string' },
    qrPaymentUri: { title: 'QR Payment URI', dataType: 'string', config: { url: true } },
    description: { title: 'Description', dataType: 'string' },
    currency: { title: 'Currency', dataType: 'string' },
    failureCode: { title: 'Failure Code', dataType: 'string' },
    failureMessage: { title: 'Failure Message', dataType: 'string' },
  },
});

import { buildEntityCallbacks, buildSchema, EntityReference } from '@camberi/firecms';

import { BlogContentField } from '../components/blogContentField';
import { BlogContentPreview } from '../components/blogContentPreview';
import { slugRe } from '../utils/buildSlug';
import { User } from './user.schema';

export interface HowToBrew {
  createdAt: Date;
  updateAt: Date;
  content: string;
  authorRef: EntityReference;
  thumbnail: string;
  title: string;
  published: boolean;
  publishedAt: Date | null;
  slug: string;
  author?: {
    firstName: User['firstName'];
    lastName: User['lastName'];
    email: User['email'];
    imageUrl: User['imageUrl'];
    title: User['title'];
    introduction: User['introduction'];
    linkedIn: User['linkedIn'];
    facebook: User['facebook'];
    twitter: User['twitter'];
  };
}

export type HowToBrewSchema = Omit<HowToBrew, 'author'>;

export const howToBrewSchema = buildSchema<HowToBrewSchema>({
  name: 'How to Brew',
  properties: {
    authorRef: { dataType: 'reference', path: 'users', title: 'Author', validation: { required: true } },

    content: {
      config: {
        Field: BlogContentField,
        Preview: BlogContentPreview,
      },
      dataType: 'string',
      title: 'Content',
      validation: { required: true },
    },

    createdAt: {
      autoValue: 'on_create',
      dataType: 'timestamp',
      title: 'Publication date',
    },

    published: {
      dataType: 'boolean',
      title: 'published',
    },

    publishedAt: {
      dataType: 'timestamp',
      readOnly: true,
      title: 'Publish Date',
    },

    slug: {
      dataType: 'string',
      title: 'Slug',
      validation: { matches: slugRe, required: true, trim: true, unique: true },
    },

    thumbnail: {
      config: {
        storageMeta: {
          acceptedFiles: ['image/*'],
          fileName: () => `blog-${Date.now()}`,
          mediaType: 'image',
          metadata: {
            cacheControl: 'max-age=1000000',
          },
          storagePath: 'blogs',
        },
      },
      dataType: 'string',
      title: 'Thumbnail',
      validation: { required: true },
    },

    title: {
      dataType: 'string',
      title: 'Title',
      validation: { required: true, trim: true },
    },

    updateAt: {
      autoValue: 'on_update',
      dataType: 'timestamp',
      title: 'Last update date',
    },
  },
});

export const howToBrewCallbacks = buildEntityCallbacks<HowToBrewSchema>({
  onPreSave: ({ values }) => {
    if (values.published) values.publishedAt = new Date();
    if (!values.published) values.publishedAt = null;

    if ((values as HowToBrew).author) delete (values as HowToBrew).author;

    return values;
  },
});

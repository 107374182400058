import { useDataSource } from '@camberi/firecms';
import { DatePicker, TimePicker } from '@mui/lab';
import { Breadcrumbs, FormControl, Paper, TextField, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';

import { OrderDocument } from '../../../../interfaces/Firestore';
import { orderSchema } from '../../../../schemas/order.schema';
import { ORDER_POSTAL } from '../../constants/order';
import FormFooter, { FORM_FOOTER } from '../FormFooter';
import InputLabel from '../InputLabel';
import SimpleSelect from '../SimpleSelect';
import Subtitle from '../Subtitle';
import { getOrderDateTime,OrderDetail } from './OrderList';

interface Props {
  onClose: (value: boolean) => void;
  order: OrderDocument | undefined;
  orderDetail: OrderDetail | undefined;
}
interface inputValues {
  shippingCourier?: string,
  shippingCourierOther?: string,
  shippingDate?: Date,
  shippingTime?: Date,
  shippedTimestamp?: Date;
  shipmentTrackingNumber?: string,
}
const defaultValues = {
  shipmentTrackingNumber: '',
  shippingCourier: ORDER_POSTAL.POSTAL.COURIER.OPTIONS[0],
  shippingCourierOther: '',
  shippingDate: new Date(),
  shippingTime: new Date(),
};
const OrderPostal: FC<Props> = ({ onClose, order, orderDetail }) => {
  const { saveEntity } = useDataSource();
  const [inputValues, setInputValues] = useState<inputValues>(defaultValues);
  const orderDetailItem = JSON.parse(JSON.stringify(orderDetail));
  useEffect(() => {
    if (order) {
      let ticket: inputValues = Object.assign({}, order.tickets[orderDetailItem.orderRoasterIndex]);
      if (ticket?.shippingCourier && !ORDER_POSTAL.POSTAL.COURIER.OPTIONS.find(item => item === ticket?.shippingCourier)) {
        ticket['shippingCourierOther'] = ticket.shippingCourier;
        ticket.shippingCourier = 'Others';
      }
      if (ticket?.shippedTimestamp) {
        const orderDateTime = getOrderDateTime(ticket?.shippedTimestamp);
        ticket['shippingDate'] = orderDateTime;
        ticket['shippingTime'] = orderDateTime;
      }
      if (Object.keys(ticket).length === 1 && Object.keys(ticket).includes('items')) ticket = { ...ticket, ...defaultValues };
      setInputValues({ ...ticket });
    }
  }, [orderDetailItem.orderRoasterIndex, order]);
  const handleOnClose = () => {
    onClose(true);
  };
  const publish = () => {
    if (order) {
      const {id, ...updatedValues} = order;
      updatedValues.tickets = updatedValues?.tickets?.map((item, index) => {
        if (index === orderDetailItem.orderRoasterIndex) {
          if (inputValues?.shippingCourier && inputValues?.shippingCourierOther && inputValues?.shippingCourier === 'Others') inputValues.shippingCourier = inputValues.shippingCourierOther;
          const getDateTime = (dateTime: Date | undefined) => {
            if (dateTime) return new Date(dateTime);
            return new Date();
          }
          const date = getDateTime(inputValues.shippingDate);
          const time = getDateTime(inputValues.shippingTime);
          inputValues['shippedTimestamp'] = new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds());
          delete inputValues.shippingCourierOther;
          delete inputValues.shippingDate;
          delete inputValues.shippingTime;
          item = { ...item, ...inputValues };
        }
        return item;
      });
      saveEntity({ entityId: id, path: `orders`, schema: orderSchema, status: 'existing', values: updatedValues });
      onClose(true);
    }
  };

  return (
    <main>
      <div className="pt-6 pl-8">
        <Breadcrumbs aria-label="breadcrumb">
          <a className="text-xs no-underline cursor-pointer" color="inherit" onClick={() => onClose(true)}>{ORDER_POSTAL.ORDERS}</a>
          <Typography className="text-xs" color="text.primary">{ORDER_POSTAL.DELIVERY}</Typography>
        </Breadcrumbs>
      </div>
      <div className="w-[616px] mx-auto">
        <form className="pb-24 flex">
          <div className="mx-auto container w-[616px]">
            <div className="flex justify-between">
              <div className="font-bold text-[32px] text-gray-700 mb-6">{ORDER_POSTAL.DELIVERY}</div>
            </div>
            <Paper id="courier">
              <div className="pl-6 pt-px pb-9 pr-8">
                <Subtitle>{ORDER_POSTAL.POSTAL.COURIER.TITLE}</Subtitle>
                <div className="flex justify-between mt-6">
                  <FormControl className="w-[272px]">
                    <InputLabel text={ORDER_POSTAL.POSTAL.COURIER.LABEL} isRequired={true} />
                    <SimpleSelect
                      value={inputValues.shippingCourier || defaultValues.shippingCourier}
                      options={ORDER_POSTAL.POSTAL.COURIER.OPTIONS}
                      onChange={(event) => setInputValues({ ...inputValues, [ORDER_POSTAL.POSTAL.COURIER.ID]: event.target.value })}
                    />
                  </FormControl>
                  <FormControl className="w-[272px]">
                    <InputLabel text={ORDER_POSTAL.POSTAL.COURIER.OTHER.LABEL} isRequired={true} />
                    <TextField id={ORDER_POSTAL.POSTAL.COURIER.OTHER.ID} value={inputValues.shippingCourierOther || defaultValues.shippingCourierOther}
                      color="secondary" fullWidth={true} size="small"
                      onChange={(event) => setInputValues({ ...inputValues, [ORDER_POSTAL.POSTAL.COURIER.OTHER.ID]: event.target.value })}
                    />
                  </FormControl>
                </div>
              </div>
            </Paper>
            <Paper id="inputValues-detail" className="mt-4">
              <div className="pl-6 pt-px pb-9 pr-8">
                <Subtitle>{ORDER_POSTAL.POSTAL.SHIPPING_DETAIL.TITLE}</Subtitle>
                <div className="flex justify-between mt-6">
                  <FormControl className="w-[272px]">
                    <InputLabel text={ORDER_POSTAL.POSTAL.SHIPPING_DETAIL.DATE.LABEL} isRequired={true} />
                    <DatePicker value={inputValues.shippingDate} inputFormat="dd/MM/yyyy"
                      renderInput={(params) => <TextField size="small" {...params} />}
                      onChange={(value) => setInputValues({ ...inputValues, [ORDER_POSTAL.POSTAL.SHIPPING_DETAIL.DATE.ID]: value?.toDateString() })} />
                  </FormControl>
                  <FormControl className="w-[272px]">
                    <InputLabel text={ORDER_POSTAL.POSTAL.SHIPPING_DETAIL.TIME.LABEL} isRequired={true} />
                    <TimePicker value={inputValues.shippingTime}
                      renderInput={(params) => <TextField size="small" {...params} />}
                      onChange={(value) => setInputValues({ ...inputValues, [ORDER_POSTAL.POSTAL.SHIPPING_DETAIL.TIME.ID]: value })} />
                  </FormControl>
                </div>
                <div className="flex justify-between mt-6">
                  <FormControl className="w-[272px]">
                    <InputLabel text={ORDER_POSTAL.POSTAL.SHIPPING_DETAIL.TRACKING.LABEL} isRequired={true} />
                    <TextField id={ORDER_POSTAL.POSTAL.SHIPPING_DETAIL.TRACKING.ID} value={inputValues.shipmentTrackingNumber}
                      color="secondary" fullWidth={true} size="small"
                      onChange={(event) => setInputValues({ ...inputValues, [ORDER_POSTAL.POSTAL.SHIPPING_DETAIL.TRACKING.ID]: event.target.value })}
                    />
                  </FormControl>
                </div>
              </div>
            </Paper>
          </div>
        </form>
      </div>
      <FormFooter hideSaveDraft={true} onPublish={publish} onClose={handleOnClose} publishMsg={FORM_FOOTER.PUBLISH.CONFIRM}/>
    </main>
  );
};

export default OrderPostal;

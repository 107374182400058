/* eslint-disable sort-keys-fix/sort-keys-fix */
import { buildEntityCallbacks, buildSchema, EntityReference } from '@camberi/firecms';

import { BlogContentField } from '../components/blogContentField';
import { BlogContentPreview } from '../components/blogContentPreview';
import { slugRe } from '../utils/buildSlug';
import { User } from './user.schema';

export interface Blog {
  createdAt: Date;
  updateAt: Date;
  content: string;
  published: boolean;
  authorRef: EntityReference;
  thumbnail: string;
  title: string;
  publishedAt: Date | null;
  tags: string[];
  pageViews: number;
  slug: string;
  author?: {
    firstName: User['firstName'];
    lastName: User['lastName'];
    email: User['email'];
  };
}

export type BlogSchema = Omit<Blog, 'author'>;

export const blogSchema = buildSchema<BlogSchema>({
  name: 'Blog',
  properties: {
    published: {
      dataType: 'boolean',
      title: 'published',
    },

    title: {
      dataType: 'string',
      title: 'Title',
      validation: { required: true, trim: true },
    },

    slug: {
      dataType: 'string',
      title: 'Slug',
      validation: { matches: slugRe, required: true, trim: true, unique: true },
    },

    authorRef: { dataType: 'reference', path: 'users', title: 'Author', validation: { required: true } },

    thumbnail: {
      config: {
        storageMeta: {
          acceptedFiles: ['image/*'],
          fileName: () => `blog-${Date.now()}`,
          mediaType: 'image',
          metadata: {
            cacheControl: 'max-age=1000000',
          },
          storagePath: 'blogs',
        },
      },
      dataType: 'string',
      title: 'Thumbnail',
      validation: { required: true },
    },

    tags: {
      dataType: 'array',
      of: {
        dataType: 'string',
        validation: { trim: true },
      },
      title: 'Tags',
    },

    content: {
      config: {
        Field: BlogContentField,
        Preview: BlogContentPreview,
      },
      dataType: 'string',
      title: 'Content',
      validation: { required: true },
    },

    publishedAt: {
      dataType: 'timestamp',
      readOnly: true,
      title: 'Publish Date',
    },

    createdAt: {
      autoValue: 'on_create',
      dataType: 'timestamp',
      readOnly: true,
      title: 'Publication date',
    },

    updateAt: {
      autoValue: 'on_update',
      dataType: 'timestamp',
      readOnly: true,
      title: 'Last update date',
    },

    pageViews: {
      dataType: 'number',
      title: 'Page Views',
      readOnly: true,
    },
  },
});

export const blogCallbacks = buildEntityCallbacks<BlogSchema>({
  onPreSave: ({ values }) => {
    if (values.published) values.publishedAt = new Date();
    if (!values.published) values.publishedAt = null;

    if ((values as Blog).author) delete (values as Blog).author;

    return values;
  },
});

import { FormControl, OutlinedInput, Typography } from '@mui/material';
import { ChangeEvent, FC } from 'react';

interface Props {
  label: string;
  width?: string;
  required?: boolean;
  value?: string | number | undefined;
  height?: string;
  id?: string;
  name?: string;
  type?: 'text' | 'number';
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const InputWithLabel: FC<Props> = ({
  label,
  height,
  width,
  required = false,
  value = '',
  type = 'text',
  id,
  name,
  onChange,
}) => {
  return (
    <FormControl variant="standard" sx={{ m: 0, width: 272 }}>
      <Typography variant="body1" gutterBottom>
        {label}
        {required && <span className="text-red-600">&nbsp;*</span>}
      </Typography>
      <OutlinedInput
        id={id}
        name={name}
        className={width + ' ' + height}
        size="small"
        type={type}
        value={value}
        inputProps={{
          'aria-label': 'weight',
        }}
        onChange={onChange}
      />
    </FormControl>
  );
};

export default InputWithLabel;

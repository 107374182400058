import { Typography } from '@mui/material';
import { FC } from 'react';

export const Subtitle: FC = ({ children }) => (
  <Typography variant="subtitle1" component="div" className="mt-7 font-bold text-gray-500">
    {children}
  </Typography>
);

export default Subtitle;

import 'typeface-rubik';
import 'typeface-space-mono';

import {
  Authenticator,
  buildCollection,
  CMSView,
  EntityCollection,
  FirebaseCMSApp,
  NavigationBuilder,
} from '@camberi/firecms';
import { StyledEngineProvider } from '@mui/material/styles';
import { connectAuthEmulator, getAuth, User as FirebaseUser } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { FC } from 'react';

import { firebaseConfig, isLocal } from './config';
import { blogCallbacks, blogSchema } from './schemas/blog.schema';
import { cartSchema } from './schemas/cart.schema';
import {
  couponCallbacks,
  CouponSchema,
  reservationsColumnDelegate,
  usagesColumnDelegate,
} from './schemas/coupon.schema';
import { employmentCallbacks, employmentSchema } from './schemas/employment.schema';
import { howToBrewCallbacks, howToBrewSchema } from './schemas/howToBrew.schema';
import { orderManagementColumnDelegate, orderSchema } from './schemas/order.schema';
import { paymentSchema } from './schemas/payment.schema';
import { presetBoxCallbacks, presetBoxSchema } from './schemas/presetBoxes.schema';
import {
  addStockColumnDelegate,
  productCallbacks,
  productSchema,
  reservedStockColumnDelegate,
  roastedDateColumnDelegate,
  roasterNameColumnDelegate,
  salesColumnDelegate,
  stockColumnDelegate,
} from './schemas/product.schema';
import { quizSchema } from './schemas/quiz.schema';
import { roasterSchema } from './schemas/roaster.schema';
import { userSchema } from './schemas/user.schema';
import OrderManagementView from './views/OrderManagementView';
import RoasterManagementView from './views/RoasterManagementView';

const authenticator: Authenticator = async ({ user: _user, authController }) => {
  const user = _user as FirebaseUser;

  console.info('Logged in as:', user?.email);

  if (!user) {
    console.info('Not Logged In.');
    return false;
  }

  const tokenResult = await user.getIdTokenResult(true);
  const isAdmin = tokenResult.claims?.admin;
  const roasterAccess = Object.keys((tokenResult.claims?.roasters as Record<string, string>) || {});

  if (!isAdmin && !roasterAccess.length) {
    console.info('CMS Access Denied.');
    return false;
  }

  authController.setExtra({ isAdmin, roasterAccess });

  console.info('CMS Access Granted.');

  return true;
};

// eslint-disable-next-line max-lines-per-function
const navigation: NavigationBuilder = async ({ authController, dataSource }) => {
  const adminCollections = [
    buildCollection({
      name: 'Roasters',
      path: 'roasters',
      permissions: {
        create: true,
        delete: true,
        edit: true,
      },
      schema: roasterSchema,
      subcollections: [
        buildCollection({
          additionalColumns: [
            roastedDateColumnDelegate,
            stockColumnDelegate,
            salesColumnDelegate,
            reservedStockColumnDelegate,
            addStockColumnDelegate,
            roasterNameColumnDelegate,
          ],
          callbacks: productCallbacks,
          name: 'Products',
          path: 'products',
          permissions: {
            create: true,
            delete: true,
            edit: true,
          },
          schema: productSchema,
        }),
        buildCollection({
          callbacks: employmentCallbacks,
          name: 'Employments',
          path: 'employments',
          permissions: {
            create: true,
            delete: true,
            edit: true,
          },
          schema: employmentSchema,
        }),
      ],
    }),
    buildCollection({
      name: 'Users',
      path: 'users',
      permissions: {
        create: false,
        delete: false,
        edit: false,
      },
      schema: userSchema,
      subcollections: [
        buildCollection({
          name: 'Payments',
          path: 'payments',
          permissions: {
            create: false,
            delete: false,
            edit: false,
          },
          schema: paymentSchema,
        }),
      ],
    }),
    buildCollection({
      additionalColumns: [orderManagementColumnDelegate],
      initialSort: ['orderTimestamp', 'desc'],
      name: 'Orders',
      path: 'orders',
      permissions: {
        create: false,
        delete: false,
        edit: true,
      },
      schema: orderSchema,
    }),
    buildCollection({
      name: 'Carts',
      path: 'carts',
      permissions: {
        create: false,
        delete: false,
        edit: true,
      },
      schema: cartSchema,
    }),
    buildCollection({
      name: 'Quizzes',
      path: 'quizzes',
      permissions: {
        create: true,
        delete: true,
        edit: true,
      },
      schema: quizSchema,
    }),
    buildCollection({
      callbacks: presetBoxCallbacks,
      initialSort: ['setNumber', 'asc'],
      name: 'Preset Boxes',
      path: 'presetBoxes',
      permissions: {
        create: true,
        delete: true,
        edit: true,
      },
      schema: presetBoxSchema,
    }),
    buildCollection({
      callbacks: couponCallbacks,
      name: 'Coupons',
      path: 'coupons',
      permissions: {
        create: true,
        delete: true,
        edit: true,
      },
      schema: CouponSchema,
    }),
    buildCollection({
      callbacks: blogCallbacks,
      name: 'Blogs',
      path: 'blogs',
      permissions: {
        create: true,
        delete: true,
        edit: true,
      },
      schema: blogSchema,
    }),
    buildCollection({
      callbacks: howToBrewCallbacks,
      name: 'How To Brew',
      path: 'howToBrew',
      permissions: {
        create: true,
        delete: true,
        edit: true,
      },
      schema: howToBrewSchema,
    }),
  ];

  const isAdmin = !!authController.extra?.isAdmin;
  const roasterAccess = (authController.extra?.roasterAccess as string[]) || [];
  const roasters = (
    await Promise.all(
      roasterAccess.map((entityId) => dataSource.fetchEntity({ entityId, path: 'roasters', schema: roasterSchema })),
    )
  ).filter(Boolean);

  const collections: EntityCollection[] = [...(isAdmin ? adminCollections : [])];

  const managementPaths = [
    'shop',
    // 'shop/edit',
    'products',
    // 'products/new',
    // 'products/:productId/edit',
    // 'products/:productId/copy',
    'orders',
    // 'parcels',
    // 'parcels/edit',
    // 'deliveries',
    // 'deliveries/address/new',
    'export',
  ];
  const views: CMSView[] = [
    ...(isAdmin
      ? [
          {
            description: 'Export orders',
            name: 'Order Management',
            path: ['order-management', 'order-management/:orderId'],
            view: <OrderManagementView />,
          },
        ]
      : []),
    ...(roasters.map((roaster) => ({
      description: `Use this area to manage your shop information, including products and orders.`,
      name: `Manage ${roaster.values.name}`,
      path: [`roaster/${roaster.id}`, ...managementPaths.map((path) => `roaster/${roaster.id}/${path}`)],
      view: <RoasterManagementView />,
    })) || []),
  ];

  return {
    collections,
    views,
  };
};

const App: FC = () => {
  return (
    <StyledEngineProvider injectFirst>
      <FirebaseCMSApp
        name={'Koph CMS'}
        authentication={authenticator}
        navigation={navigation}
        firebaseConfig={firebaseConfig}
        onFirebaseInit={() => {
          if (isLocal) {
            connectFirestoreEmulator(getFirestore(), 'localhost', 8080);
            connectAuthEmulator(getAuth(), 'http://localhost:9099/');
            connectStorageEmulator(getStorage(), 'localhost', 9199);
          }
        }}
        primaryColor="#f31616"
        secondaryColor="#A2A2A2"
      />
    </StyledEngineProvider>
  );
};

export default App;

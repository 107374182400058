import { Entity } from '@camberi/firecms';
import CloseIcon from '@mui/icons-material/Close';
import { Backdrop, Box, Button, Grid, Modal, Paper, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import { FC, useEffect, useState } from 'react';

import { OrderDocument } from '../../../../interfaces/Firestore';
import { Roaster } from '../../../../schemas/roaster.schema';
import { ORDER_DETAIL } from '../../constants/order';
import { AlertFeedback } from '../AlertFeedback';
import { OrderDetail } from './OrderList';

interface Props {
  onOpen: boolean;
  onClose: (value: boolean) => void;
  order: OrderDocument | undefined;
  roaster: Entity<Roaster> | undefined;
  orderDetail: OrderDetail | undefined;
}
const style = {
  bgcolor: 'background.paper',
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  left: '50%',
  maxHeight: '100%',
  overflowY: 'none',
  pb: 14,
  position: 'absolute' as const,
  pt: 2,
  px: 3,
  transform: 'translate(-50%, 65px)',
  width: 616,
};

const OrderHeader: FC<{
  order: OrderDocument | undefined;
}> = ({ order }) => {
  return (
    <>
      <Grid sx={{ display: 'flex', marginTop: '24px' }}>
        <Typography id="modal-modal-description" marginRight="32px">
          {ORDER_DETAIL.LABEL}
        </Typography>
        <Typography fontWeight="bold" marginRight="6px">
          {order?.shippingAddress?.firstName}
        </Typography>
        <Typography fontWeight="bold">{order?.shippingAddress?.lastName}</Typography>
      </Grid>
      <Grid sx={{ display: 'flex', marginTop: '8px' }}>
        <Typography id="modal-modal-description" marginRight="43px">
          {ORDER_DETAIL.ORDER_ID}:
        </Typography>
        <Typography fontWeight="bold">{order?.id}</Typography>
      </Grid>
    </>
  );
};

const OrderContent: FC<{
  onCopy: boolean;
  onCopied: (value: boolean) => void;
  order: OrderDocument | undefined;
  roaster: Entity<Roaster> | undefined;
  orderDetail: OrderDetail | undefined;
}> = ({ order, orderDetail, roaster, onCopy, onCopied }) => {
  const orderDetailItem = JSON.parse(JSON.stringify(orderDetail));
  const orderTicket = order?.tickets[orderDetailItem.orderRoasterIndex];
  const [openAlert, setOpenAlert] = useState(false);

  const handleOnCloseAlert = (reason?: string) => {
    if (reason === 'clickaway') return;
    setOpenAlert(false);
  };

  useEffect(() => {
    if (onCopy) {
      const text = document.getElementById('order-all-detail');
      navigator.clipboard.writeText(text?.innerText || '');
      setOpenAlert(true);
      onCopied(true);
    }
  }, [onCopy, onCopied]);

  return (
    <>
      <AlertFeedback open={openAlert} message={ORDER_DETAIL.ALERT_INFO} type="info" onClose={handleOnCloseAlert} />
      <Paper
        id="order-all-detail"
        sx={{ bgcolor: blue[50], flexGrow: 1, height: '100vh', mb: 3, mt: 3, overflow: 'auto', p: 4 }}
      >
        <Typography sx={{ fontWeight: 'bold' }}>{ORDER_DETAIL.ORDER_TIMESTAMP}:</Typography>
        <Typography sx={{ font: 16 }}>{orderDetailItem.orderTimeStamp.toString()}</Typography>
        <Grid sx={{ display: 'flex' }}>
          <Typography sx={{ fontWeight: 'bold', marginRight: '6px' }}>{ORDER_DETAIL.STATUS}:</Typography>
          <Typography sx={{ font: 16 }}>{orderDetailItem.orderStatus}</Typography>
        </Grid>
        <Grid sx={{ display: 'flex' }}>
          <Typography sx={{ fontWeight: 'bold', marginRight: '6px' }}>{ORDER_DETAIL.USER}:</Typography>
          <Typography sx={{ font: 16 }}>
            {order?.user?.firstName} {order?.user?.lastName} ({order?.user?.email})
          </Typography>
        </Grid>
        <br />
        <Typography sx={{ fontWeight: 'bold' }}>{ORDER_DETAIL.SHIPPING_ADDRESS}:</Typography>
        <Grid sx={{ display: 'flex' }}>
          <Typography sx={{ font: 16 }} marginRight="6px">
            {order?.shippingAddress?.firstName}
          </Typography>
          <Typography sx={{ font: 16 }}>{order?.shippingAddress?.lastName}</Typography>
        </Grid>
        <Typography sx={{ font: 16 }}>
          {order?.shippingAddress?.address} {order?.shippingAddress?.subdistrict} {order?.shippingAddress?.district}{' '}
          {order?.shippingAddress?.province}
        </Typography>
        <Typography sx={{ font: 16 }}>{order?.shippingAddress?.zipCode}</Typography>
        <Typography sx={{ font: 16 }}>{order?.shippingAddress?.phoneNumber}</Typography>
        <br />
        <Grid sx={{ display: 'flex' }}>
          <Typography sx={{ font: 16, fontWeight: 'bold', marginRight: '6px' }}>{ORDER_DETAIL.SUBTOTAL}: </Typography>
          <Typography sx={{ font: 16 }}>{order?.subtotal} ฿</Typography>
        </Grid>
        <Grid sx={{ display: 'flex' }}>
          <Typography sx={{ font: 16, fontWeight: 'bold', marginRight: '6px' }}>{ORDER_DETAIL.VAT}: </Typography>
          <Typography sx={{ font: 16 }}>{order?.vat} ฿</Typography>
        </Grid>
        <Grid sx={{ display: 'flex' }}>
          <Typography sx={{ font: 16, fontWeight: 'bold', marginRight: '6px' }}>{ORDER_DETAIL.DISCOUNT}: </Typography>
          <Typography sx={{ font: 16 }}>{order?.discount} ฿</Typography>
        </Grid>
        <Grid sx={{ display: 'flex' }}>
          <Typography sx={{ font: 16, fontWeight: 'bold', marginRight: '6px' }}>
            {ORDER_DETAIL.SHIPPING_ADDRESS}:{' '}
          </Typography>
          <Typography sx={{ font: 16 }}>{order?.shipping} ฿</Typography>
        </Grid>
        <Grid sx={{ display: 'flex' }}>
          <Typography sx={{ font: 16, fontWeight: 'bold', marginRight: '6px' }}>{ORDER_DETAIL.TOTAL}:</Typography>
          <Typography sx={{ font: 16 }}> {order?.total} ฿</Typography>
        </Grid>
        <Grid sx={{ display: 'flex' }}>
          <Typography sx={{ font: 16, fontWeight: 'bold', marginRight: '6px' }}>
            {ORDER_DETAIL.PAYMENT_METHOD}:
          </Typography>
          <Typography sx={{ font: 16 }}>{order?.paymentMethod}</Typography>
        </Grid>
        <Typography sx={{ font: 16, fontWeight: 'bold', marginRight: '6px' }}>
          {ORDER_DETAIL.PAID_TIMESTAMP}:
        </Typography>
        <Typography sx={{ font: 16 }}>{orderDetailItem.orderPaidTimestamp.toString()}</Typography>
        <Typography sx={{ font: 16 }}> </Typography>
        {orderTicket?.items?.map((row, index: number) => (
          <div key={index}>
            <br />
            <Grid sx={{ display: 'flex' }}>
              <Typography sx={{ font: 16, fontWeight: 'bold', marginRight: '6px' }}>
                {ORDER_DETAIL.SUPPLIER}:
              </Typography>
              <Typography sx={{ font: 16 }}>{roaster?.values.name}</Typography>
            </Grid>
            <Grid sx={{ display: 'flex' }}>
              <Typography sx={{ font: 16, fontWeight: 'bold', marginRight: '6px' }}>
                {ORDER_DETAIL.QUANTITY}:
              </Typography>
              <Typography sx={{ font: 16 }}>{row?.quantity}</Typography>
            </Grid>
            <Grid sx={{ display: 'flex' }}>
              <Typography sx={{ font: 16, fontWeight: 'bold', marginRight: '6px' }}>{ORDER_DETAIL.PRODUCT}:</Typography>
              <Typography sx={{ font: 16 }}>{row?.name}</Typography>
            </Grid>
            <Grid sx={{ display: 'flex' }}>
              <Typography sx={{ font: 16, fontWeight: 'bold', marginRight: '6px' }}>
                {ORDER_DETAIL.GRINDING}:
              </Typography>
              <Typography sx={{ font: 16 }}>
                {(row?.metadata?.brewMethod as string) ? (row?.metadata?.brewMethod as string) : 'NO'}
              </Typography>
            </Grid>
          </div>
        ))}
      </Paper>
    </>
  );
};

const OrderAllDetail: FC<Props> = ({ onOpen, onClose, order, roaster, orderDetail }) => {
  const [open] = useState<boolean>(onOpen);
  const [copy, setCopy] = useState(false);

  const handleClose = () => onClose(false);

  const handleCopyText = () => {
    setCopy(true);
  };

  const modalTitle = (
    <div>
      <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 16, top: 12 }}>
        <CloseIcon fontSize="small" />
      </IconButton>
      <Typography id="modal-modal-title" fontSize="32px" fontWeight="bold">
        {ORDER_DETAIL.TITLE}
      </Typography>
    </div>
  );

  const modalContent = (
    <>
      <OrderHeader order={order} />
      <OrderContent
        order={order}
        orderDetail={orderDetail}
        roaster={roaster}
        onCopy={copy}
        onCopied={() => setCopy(false)}
      />
    </>
  );

  const modalFooter = (
    <div>
      <Button
        className="bg-blue-500 font-normal text-base rounded-full normal-case no-underline"
        variant="contained"
        color="info"
        onClick={handleCopyText}
        sx={{
          borderRadius: 9999,
        }}
      >
        <Typography>{ORDER_DETAIL.COPY}</Typography>
      </Button>
    </div>
  );

  return (
    <>
      <Modal open={open} onClose={handleClose} BackdropComponent={Backdrop}>
        <Box key={order?.id} sx={style}>
          {modalTitle}
          {modalContent}
          {modalFooter}
        </Box>
      </Modal>
    </>
  );
};

export default OrderAllDetail;

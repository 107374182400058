import './index.css';

import React, { StrictMode } from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';

import App from './App';
import SessionProvider from './components/SessionProvider';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <StrictMode>
    <CookiesProvider>
      <SessionProvider>
        <App />
      </SessionProvider>
    </CookiesProvider>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { FC } from 'react';

import LeftNav from './LeftNav';

interface Props {
  currentPage: string;
}

const Layout: FC<Props> = ({ children, currentPage }) => {
  return (
    <div className="w-full h-[500px]">
      <LeftNav currentPage={currentPage}>{children}</LeftNav>
    </div>
  );
};

export default Layout;

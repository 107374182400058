/* eslint-disable sort-keys-fix/sort-keys-fix */

import {
  AdditionalColumnDelegate,
  AsyncPreviewComponent,
  buildEntityCallbacks,
  buildSchema,
  Entity,
} from '@camberi/firecms';
import { deleteField, doc, getDoc, getFirestore, updateDoc } from '@firebase/firestore';
import { Timestamp } from 'firebase/firestore';

import RoastedDateField from '../components/RoastedDateField';
import StockField from '../components/StockField';
import { slugRe } from '../utils/buildSlug';
import round from '../utils/round';
import { Roaster } from './roaster.schema';

export enum TasteNote {
  'Citrus' = 'Citrus',
  'Fruit' = 'Fruit',
  'Ripe Fruit' = 'Ripe Fruit',
  'Florals' = 'Florals',
  'Tea' = 'Tea',
  'Vanilla' = 'Vanilla',
  'Sugary' = 'Sugary',
  'Chocolate' = 'Chocolate',
  'Nut' = 'Nut',
  'Roasty' = 'Roasty',
  'Earthy' = 'Earthy',
  'Spicy' = 'Spicy',
  'Savory' = 'Savory',
  'Chemical' = 'Chemical',
  'Other' = 'Other',
}

export enum BrewMethod {
  'Coffee Maker' = 'Coffee Maker',
  'French Press' = 'French Press',
  'Pour Over' = 'Pour Over',
  'Chemex' = 'Chemex',
  'Aeropress' = 'Aeropress',
  'Cold Brew' = 'Cold Brew',
  'Moka Pot & Percolator' = 'Moka Pot & Percolator',
  'Espresso Machine' = 'Espresso Machine',
  'Refillable Capsule' = 'Refillable Capsule',
}

export enum Addition {
  'Black' = 'Black',
  'Milk or Cream' = 'Milk or Cream',
  'Flavoured creamers or Syrup' = 'Flavoured creamers or Syrup',
  'Non-dairy milk' = 'Non-dairy milk',
  'Sugar or Sweetener' = 'Sugar or Sweetener',
  'Other' = 'Other',
}

export enum RoastLevel {
  'Light' = 'Light',
  'Cinnamon' = 'Cinnamon',
  'Medium' = 'Medium',
  'High' = 'High',
  'City' = 'City',
  'Full City' = 'Full City',
  'Dark' = 'Dark',
  'French' = 'French',
  'Italian' = 'Italian',
}

export interface Product {
  published: boolean;
  forSale: boolean;
  inStock: boolean;
  name: string;
  slug: string;
  image?: string;
  description: string;
  awards?: string[];
  samplePrice: number;
  tasteNotes: TasteNote[];
  tasteDescription: string;
  tasteQualities: {
    acidity: number;
    sweetness: number;
    body: number;
  };
  brewMethods: BrewMethod[];
  additions?: Addition[];
  roastLevel: RoastLevel;
  process: string;
  groundable: boolean;
  origins: {
    country: string;
    city?: string;
  }[];
  varieties?: string[];
  altitudeMeters?: number;
  externalLink?: string;
  roaster?: {
    name: Roaster['name'];
    description: Roaster['description'];
    logo: Roaster['logo'];
    slug: Roaster['slug'];
  };
  roastedDate: Date;
  quantityOptions: {
    grams: number;
    price: number;
    stock: number;
  }[];
}

export interface ProductStats {
  stockGrams: number;
  reservedStockGrams: number;
  salesGrams: number;
  roastedDate: Date;
}

export type ProductSchema = Omit<Product, 'inStock'>;

export const productSchema = buildSchema<ProductSchema>({
  defaultValues: { published: false, forSale: false },
  name: 'Product',
  properties: {
    name: {
      dataType: 'string',
      title: 'Name',
      validation: { required: true, trim: true },
    },
    published: {
      dataType: 'boolean',
      title: 'Published',
    },
    forSale: {
      dataType: 'boolean',
      title: 'For Sale',
    },
    slug: {
      dataType: 'string',
      title: 'Slug',
      validation: { matches: slugRe, trim: true, required: true, unique: true },
    },
    description: {
      columnWidth: 300,
      dataType: 'string',
      title: 'Description',
      validation: { required: true, trim: true },
    },
    quantityOptions: {
      title: 'Quantity Options and Prices',
      dataType: 'array',
      of: {
        dataType: 'map',
        properties: {
          grams: {
            dataType: 'number',
            title: 'Grams',
            validation: {
              integer: true,
              min: 0,
              required: true,
            },
          },
          price: {
            dataType: 'number',
            title: 'Price',
            validation: {
              integer: true,
              min: 0,
              required: true,
            },
          },
          stock: {
            dataType: 'number',
            title: 'Stock',
            validation: {
              integer: true,
              min: 0,
              required: true,
            },
          },
        },
      },
    },
    samplePrice: {
      dataType: 'number',
      description: 'Price in baht. for sample bag',
      title: 'Sample Price',
      validation: {
        min: 0,
        required: true,
      },
    },
    image: ({ path }) => ({
      config: {
        storageMeta: {
          acceptedFiles: ['image/*'],
          fileName: () => `image-${Date.now()}`,
          mediaType: 'image',
          metadata: {
            cacheControl: 'max-age=1000000',
          },
          storagePath: (context) => `roasters/${path.split('/')[1]}/products/${context.entityId}`,
        },
      },
      dataType: 'string',
      title: 'Image',
    }),
    process: { dataType: 'string', title: 'Process', validation: { required: true, trim: true } },
    roastLevel: {
      config: { enumValues: RoastLevel },
      dataType: 'string',
      title: 'Roast Level',
      validation: { required: true },
    },
    brewMethods: {
      dataType: 'array',
      of: { config: { enumValues: BrewMethod }, dataType: 'string' },
      title: 'Brew Methods',
      validation: { min: 1, required: true },
    },
    tasteNotes: {
      dataType: 'array',
      of: {
        dataType: 'string',
        title: 'Components',
        config: { enumValues: TasteNote },
        validation: { uniqueInArray: true },
      },
      validation: { required: true, min: 1 },
      title: 'Taste Notes',
    },
    tasteDescription: {
      dataType: 'string',
      title: 'Taste Note Description',
      validation: { required: true, trim: true },
    },
    tasteQualities: {
      dataType: 'map',
      description: 'Acididty, Sweetness, and Body',
      properties: {
        acidity: { dataType: 'number', title: 'Acidity (1 - 10)', validation: { max: 10, min: 1, required: true } },
        body: { dataType: 'number', title: 'Body (1 - 10)', validation: { max: 10, min: 1, required: true } },
        sweetness: {
          dataType: 'number',
          title: 'Sweetness (1 - 10)',
          validation: { max: 10, min: 1, required: true },
        },
      },
      title: 'Taste Qualities',
      validation: { required: true },
    },
    varieties: { dataType: 'array', of: { dataType: 'string', validation: { trim: true } }, title: 'Varieties' },
    origins: {
      dataType: 'array',
      of: {
        dataType: 'map',
        properties: {
          country: { dataType: 'string', title: 'Country', validation: { required: true, trim: true } },
          city: { dataType: 'string', title: 'City', validation: { trim: true } },
        },
        validation: { uniqueInArray: true },
      },
      title: 'Origins',
      validation: { required: true, min: 1 },
    },
    groundable: {
      dataType: 'boolean',
      description: 'Can this coffee be sold ground?',
      title: 'Ground',
    },
    altitudeMeters: {
      dataType: 'number',
      description: 'Altitude in meters.',
      title: 'Altitude',
      validation: { positive: true },
    },
    awards: { dataType: 'array', of: { dataType: 'string', validation: { trim: true } }, title: 'Awards' },
    additions: {
      dataType: 'array',
      description: 'Roaster recommended additions for this coffee.',
      of: { config: { enumValues: Addition }, dataType: 'string' },
      title: 'Additions',
    },
    externalLink: {
      dataType: 'string',
      description: 'Link to product on external platform.',
      config: { url: true },
      title: 'External Link',
    },
    roastedDate: ({ path }) => ({
      dataType: 'timestamp',
      title: 'Roasted Date',
      config: {
        Field: RoastedDateField,
        customProps: { path },
      },
    }),
  },
});

export const productCallbacks = buildEntityCallbacks<ProductSchema>({
  onPreSave: async ({ values, path, entityId }) => {
    const { roastedDate, samplePrice, ...productValues } = values;

    if (values.quantityOptions?.length) {
      const quantityOptionGrams = values.quantityOptions.map(({ grams }) => grams);

      if (quantityOptionGrams.length !== Array.from(new Set(quantityOptionGrams)).length)
        throw new Error('Quantity Options must have unique gram sizes.');
    }

    if (entityId && roastedDate !== undefined) {
      const db = getFirestore();
      const productStatsRef = doc(db, path, entityId, 'private/stats');

      await updateDoc(productStatsRef, { roastedDate: roastedDate || deleteField() });
    }

    return { ...productValues, ...(samplePrice && { samplePrice: round(samplePrice) }) };
  },
});

export const getProductStats = async ({ path, id }: Entity<ProductSchema>): Promise<ProductStats | null> => {
  const db = getFirestore();
  const productStatsRef = doc(db, path, id, 'private/stats');
  const snapshot = await getDoc(productStatsRef);

  if (snapshot.exists()) return snapshot.data() as ProductStats;

  return null;
};

export const roastedDateColumnDelegate: AdditionalColumnDelegate<ProductSchema> = {
  id: 'roasted_date',
  title: 'Last Roasted On',
  builder: ({ entity }) => {
    return (
      <AsyncPreviewComponent
        builder={getProductStats(entity).then(
          (stats) => (stats?.roastedDate as unknown as Timestamp)?.toDate()?.toDateString() || '',
        )}
      />
    );
  },
};

export const stockColumnDelegate: AdditionalColumnDelegate<ProductSchema> = {
  id: 'stock',
  title: 'Stock (grams)',
  builder: ({ entity }) => {
    return <AsyncPreviewComponent builder={getProductStats(entity).then((stats) => stats?.stockGrams || 0)} />;
  },
};

export const addStockColumnDelegate: AdditionalColumnDelegate<ProductSchema> = {
  id: 'addStock',
  title: 'Add Stock (grams)',
  builder: ({ entity }) => {
    return <StockField entity={entity} />;
  },
};

export const salesColumnDelegate: AdditionalColumnDelegate<ProductSchema> = {
  id: 'sales',
  title: 'Sales (grams)',
  builder: ({ entity }) => {
    return <AsyncPreviewComponent builder={getProductStats(entity).then((stats) => stats?.salesGrams || 0)} />;
  },
};

export const reservedStockColumnDelegate: AdditionalColumnDelegate<ProductSchema> = {
  id: 'reservedStock',
  title: 'Reserved Stock (grams)',
  builder: ({ entity }) => {
    return <AsyncPreviewComponent builder={getProductStats(entity).then((stats) => stats?.reservedStockGrams || 0)} />;
  },
};

export const roasterNameColumnDelegate: AdditionalColumnDelegate<ProductSchema> = {
  id: 'roasterName',
  title: 'Roaster Name',
  builder: ({ entity: { path, id } }) => {
    return (
      <AsyncPreviewComponent
        builder={getDoc(doc(getFirestore(), path, id)).then((product) =>
          product?.exists() ? product.data().roaster?.name : 'Unknown',
        )}
      />
    );
  },
};

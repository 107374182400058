import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { FC } from 'react';

interface IMultiSelectProps {
  id?: string;
  options: string[];
  value: string[];
  limitTags?: number;
  onChange: (value: string[] | undefined) => void | undefined;
}

export const MultiSelect: FC<IMultiSelectProps> = ({ id, options, value, limitTags = 2, onChange }) => {
  return (
    <Autocomplete
      multiple
      id={id}
      options={options}
      value={value}
      autoComplete={true}
      limitTags={limitTags}
      disableCloseOnSelect={true}
      disableClearable={true}
      size="small"
      style={{ width: '100%' }}
      getOptionLabel={(option) => option}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox style={{ marginRight: 8 }} checked={selected} />
          {option}
        </li>
      )}
      renderInput={(params) => <TextField {...params} />}
      onChange={(event, value) => onChange(value)}
    />
  );
};

export default MultiSelect;

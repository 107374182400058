import { FC, ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { pathPageMap } from '../../views/RoasterManagementView';

interface Props {
  name: string;
  isSelected?: boolean;
  icon: ReactElement<string, string>;
  currentPage: string;
}

const MenuItem: FC<Props> = ({ name, icon, currentPage }) => {
  const location = useLocation();
  const [, , roasterId] = location.pathname.split('/');
  const page = Object.entries(pathPageMap).find(([, val]) => val === name)?.[0] || '';

  return name === currentPage ? (
    <div className="flex border-l-8 border-t-0 border-r-0 border-b-0 border-solid border-blue-3 bg-blue-2 h-14">
      <div className="flex items-center pl-[30px]">
        {icon}
        <div className="text-lg font-normal pl-4 text-white">{name}</div>
      </div>
    </div>
  ) : (
    <Link to={`/roaster/${roasterId}/${page}`} className="no-underline">
      <div className={'flex items-center pl-[38px] h-14 cursor-pointer'}>
        {icon}
        <div className="text-lg font-normal pl-4 text-white">{name}</div>
      </div>
    </Link>
  );
};

export default MenuItem;

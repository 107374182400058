import { Entity } from '@camberi/firecms';
import { Breadcrumbs, Typography } from '@mui/material';
import { FC } from 'react';

import { ProductSchema } from '../../../../schemas/product.schema';
import { Roaster } from '../../../../schemas/roaster.schema';
import ProductForm from './ProductForm';

interface Props {
  roaster: Entity<Roaster> | undefined;
  product: Entity<ProductSchema> | undefined;
  onUpdated: () => void;
  onClose: (value: boolean) => void;
}

const ProductEdit: FC<Props> = ({ roaster, product, onUpdated, onClose }) => {
  return (
    <div>
      <div className="pt-6 pl-8">
        <Breadcrumbs aria-label="breadcrumb">
          <a className="text-xs no-underline cursor-pointer" color="inherit" onClick={() => onClose(true)}>
            My Products
          </a>
          <Typography className="text-xs" color="text.primary">
            Product
          </Typography>
        </Breadcrumbs>
      </div>
      <ProductForm roaster={roaster} product={product} onUpdated={onUpdated} onClose={() => onClose(true)} />
    </div>
  );
};

export default ProductEdit;

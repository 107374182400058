import { Entity, useSnackbarController } from '@camberi/firecms';
import { DateTimePicker, LoadingButton } from '@mui/lab';
import {
  Backdrop,
  Box,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from '@mui/material';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import React, { FC, useEffect, useState } from 'react';

import { Order, OrderSchema } from '../../schemas/order.schema';

const couriers = ['Flash Express', 'J&T Express', 'Kerry Express'];

interface Props {
  open?: boolean;
  onClose?: () => void;
  order: Entity<OrderSchema>;
  ticketIdx?: number;
  currentValues?: { timestamp?: Date; courier?: string; trackingNumber?: string; complete?: boolean };
  hideCourier?: boolean;
  refresh?: () => void;
}

// eslint-disable-next-line max-lines-per-function
const ShipmentManagementModal: FC<Props> = ({
  open = false,
  onClose,
  ticketIdx,
  order,
  currentValues,
  hideCourier = false,
  refresh = () => undefined,
}) => {
  const snackbarController = useSnackbarController();
  const [saving, setSaving] = useState(false);
  const [timestamp, setTimestamp] = useState<Date | null>(currentValues?.timestamp || null);
  const [courier, setCourier] = useState<string>(currentValues?.courier || '');
  const [trackingNumber, setTrackingNumber] = useState<string>(currentValues?.trackingNumber || '');
  const [complete, setComplete] = useState(currentValues?.complete || false);

  useEffect(() => {
    setTimestamp(currentValues?.timestamp || null);
    setCourier(currentValues?.courier || '');
    setTrackingNumber(currentValues?.trackingNumber || '');
    setComplete(currentValues?.complete || false);
  }, [currentValues]);

  const handleSave = async () => {
    if (typeof ticketIdx !== 'number') {
      console.error('Ticket index not defined.');
      return;
    }

    setSaving(true);
    try {
      const modified =
        (currentValues?.timestamp?.getTime() || null) !== (timestamp?.getTime() || null) ||
        (currentValues?.courier || '') !== (courier || '') ||
        (currentValues?.trackingNumber || '') !== (trackingNumber || '') ||
        (currentValues?.complete || false) !== (complete || false);

      if (modified) {
        const db = getFirestore();
        const orderRef = doc(db, order.path, order.id);
        const update: { tickets: Order['tickets'] } = {
          tickets: (order.values as Order).tickets.map((ticket, idx) =>
            idx === ticketIdx
              ? {
                  ...ticket,
                  ...(trackingNumber && { shipmentTrackingNumber: trackingNumber }),
                  ...(courier && { shippingCourier: courier }),
                  ...(timestamp && { shippedTimestamp: timestamp }),
                  deliveryComplete: complete || false,
                }
              : ticket,
          ),
        };

        await updateDoc(orderRef, update);
        snackbarController.open({ message: 'Shipping information updated.', type: 'success' });
        refresh();
      }
    } catch (error) {
      console.error('Shipping Management Error', error);
      snackbarController.open({ message: 'Error saving shipping information.', type: 'error' });
    } finally {
      setSaving(false);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        open={open}
        onClose={onClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              display: 'flex',
              flexDirection: 'column',
              left: '50%',
              p: 4,
              position: 'absolute',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
            }}
          >
            <FormControl style={{ gap: 20 }}>
              <DateTimePicker
                label="Shipped Timestamp"
                value={timestamp}
                onChange={(newValue) => {
                  setTimestamp(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              {!hideCourier && (
                <Select value={courier} label="Courier" onChange={(event) => setCourier(event.target.value)}>
                  {['', ...couriers].map((val) => (
                    <MenuItem key={val || 'default'} value={val}>
                      {val}&nbsp;
                    </MenuItem>
                  ))}
                </Select>
              )}
              <TextField
                label="Tracking Number"
                variant="outlined"
                value={trackingNumber}
                onChange={(event) => setTrackingNumber(event.target.value)}
              />
              <FormControlLabel
                control={<Checkbox checked={complete} onChange={(event) => setComplete(event.target.checked)} />}
                label="Delivery Complete"
              />
            </FormControl>

            <LoadingButton onClick={handleSave} loading={saving}>
              Save
            </LoadingButton>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ShipmentManagementModal;

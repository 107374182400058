import { Typography } from '@mui/material';
import { FC } from 'react';

export const Title: FC = ({ children }) => (
  <Typography variant="h5" component="div" className="font-bold text-[22px] text-gray-500">
    {children}
  </Typography>
);

export default Title;

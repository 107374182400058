import { ShippingAddress } from '../schemas/user.schema';
import api from './api';

interface FlashOrder {
  dstStoreName: string;
  earlyFlightEnabled: boolean;
  lineCode: string;
  mchId: string;
  outTradeNo: string;
  notice: string | null;
  packEnabled: boolean;
  dstPostalCode: string;
  pno: string;
  sameProvince: boolean;
  sortCode: string;
  sortingLineCode: string;
  srcPostalCode: string;
  upcountry: boolean;
  upcountryAmount: number;
  upcountryCharge: boolean;
}

interface FlashCourierNotification {
  notice: null;
  staffInfoId: null;
  staffInfoName: null;
  staffInfoPhone: null;
  ticketMessage: null;
  ticketPickupId: number;
  timeoutAtText: null;
  upCountryNote: null;
}

export interface FlashAddress {
  name: string;
  address: string;
  subdistrict: string;
  district: string;
  province: string;
  zipCode: string;
  phoneNumber: string;
}

export const createFlashOrder = async (
  orderId: string,
  {
    ticketIdx,
    sourceAddress,
    dimensions,
  }: {
    ticketIdx: number;
    sourceAddress: ShippingAddress;
    dimensions: { weight: number; width: number; length: number; height: number };
  },
): Promise<FlashOrder> =>
  api<{ flashOrder: FlashOrder }>(
    `orders/${orderId}/flash`,
    { body: { dimensions, sourceAddress, ticketIdx }, method: 'POST' },
    true,
  ).then((response) => response.flashOrder);

export const getFlashLabel = async (orderId: string, ticketIdx: number): Promise<Blob> =>
  api<Blob>(`orders/${orderId}/flash/label?ticketIdx=${ticketIdx}`);

export const notifyFlashCourier = async (
  roasterId: string,
  {
    sourceAddress,
    numParcels,
  }: {
    sourceAddress: FlashAddress;
    numParcels?: number;
  },
): Promise<FlashCourierNotification> =>
  api<{ notification: FlashCourierNotification }>(
    `flash/notify`,
    { body: { numParcels: numParcels || 1, roasterId, sourceAddress }, method: 'POST' },
    true,
  ).then((response) => response.notification);

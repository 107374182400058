import DoorFrontIcon from '@mui/icons-material/DoorFront';
import FolderIcon from '@mui/icons-material/Folder';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import ReceiptIcon from '@mui/icons-material/Receipt';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { FC } from 'react';

import MenuItem from './MenuItem';

interface Props {
  currentPage: string;
}

const LeftNav: FC<Props> = ({ children, currentPage }) => {
  return (
    <div className="flex">
      <div className="bg-blue-1 min-w-[232px] min-h-screen">
        <div className="pt-14">
          <MenuItem
            currentPage={currentPage}
            icon={<DoorFrontIcon className="text-white" fontSize="medium" />}
            name="Home"
          />
          <MenuItem
            currentPage={currentPage}
            icon={<StorefrontIcon className="text-white" fontSize="medium" />}
            name="My Shop"
          />
          <MenuItem
            currentPage={currentPage}
            icon={<FreeBreakfastIcon className="text-white" fontSize="medium" />}
            name="My Products"
          />
          <MenuItem
            currentPage={currentPage}
            icon={<ReceiptIcon className="text-white" fontSize="medium" />}
            name="My Orders"
          />
          {/* <MenuItem
            currentPage={currentPage}
            icon={<BentoIcon className="text-white" fontSize="medium" />}
            name="My Parcels"
          /> */}
          {/* <MenuItem
            currentPage={currentPage}
            icon={<MapIcon className="text-white" fontSize="medium" />}
            name="My Deliveries"
          /> */}
          <MenuItem
            currentPage={currentPage}
            icon={<FolderIcon className="text-white" fontSize="medium" />}
            name="Export Data"
          />
        </div>
      </div>
      {children}
    </div>
  );
};

export default LeftNav;

/* eslint-disable max-lines-per-function */
import { Entity, useDataSource, useSnackbarController } from '@camberi/firecms';
import { Button, Paper, Typography } from '@mui/material';
import { FC, useState } from 'react';

import { Roaster, roasterSchema } from '../../../schemas/roaster.schema';
import getImageUrl from '../../../utils/getImageUrl';
import EditShopForm from '../components/shop/EditShopForm';

interface Props {
  roaster: Entity<Roaster>;
  refreshRoaster?: () => void;
}

const MyShopPage: FC<Props> = ({ roaster, refreshRoaster = () => undefined }) => {
  const [isEditing, setIsEditing] = useState(false);
  const { saveEntity } = useDataSource();
  const [submitting, setSubmitting] = useState(false);
  const snackbarController = useSnackbarController();

  const logoUrl = roaster?.values?.logo;

  const handleSubmit = (data: Partial<Roaster>) => {
    const { name, description, awards } = data;
    const values = { awards: awards?.filter(Boolean).map((award) => award.trim()) || [], description, name };

    setSubmitting(true);

    saveEntity({
      entityId: roaster.id,
      path: 'roasters',
      schema: roasterSchema,
      status: 'existing',
      values,
    })
      .then(() => {
        refreshRoaster();
      })
      .catch((error) => {
        console.error('Error saving roaster data:', error);
        snackbarController.open({
          message: 'Save failed. Try again or contact support.',
          title: 'Error',
          type: 'error',
        });
      })
      .finally(() => {
        setSubmitting(false);
        setIsEditing(false);
      });
  };

  return (
    <div className="relative pt-[48px] pl-[56px] pr-[72px] w-full">
      {isEditing ? (
        <EditShopForm
          defaultValues={roaster?.values}
          onCancel={() => setIsEditing(false)}
          submitting={submitting}
          onSubmit={handleSubmit}
        />
      ) : (
        <>
          <div className="flex justify-between">
            <div>
              <Typography className="font-bold text-3xl">My Shop</Typography>
            </div>
            <div>
              <Button
                className="bg-white text-blue-500 font-normal text-base rounded-full normal-case border-blue-500"
                variant="outlined"
                onClick={() => setIsEditing(!isEditing)}
              >
                Edit My Shop
              </Button>
            </div>
          </div>
          <Paper className="mt-[32px]">
            <div className="px-[80px] py-[56px]">
              <div className="flex">
                {logoUrl && <img src={getImageUrl(roaster?.values?.logo)} width="160" />}
                <Typography className="pl-[56px] font-bold text-3xl">{roaster?.values?.name}</Typography>
              </div>
              <div className="pt-[32px]">
                <Typography>{roaster?.values?.description}</Typography>
              </div>
              <div>
                <Typography className="font-bold text-base pt-[32px] pb-[20px]">รางวัลที่เคยได้รับ</Typography>
                {roaster &&
                  roaster.values &&
                  roaster.values.awards &&
                  roaster.values.awards.map((award, index) => <Typography key={index}>{award}</Typography>)}
              </div>
            </div>
          </Paper>
        </>
      )}
    </div>
  );
};

export default MyShopPage;

import { LoadingButton } from '@mui/lab';
import { Breadcrumbs, Button, FormControl, FormHelperText, Paper, TextField, Typography } from '@mui/material';
import { ChangeEvent, FC, FormEventHandler, useState } from 'react';

import { Roaster } from '../../../../schemas/roaster.schema';
import InputWithLabel from '../InputWithLabel';

interface Props {
  defaultValues?: Roaster;
  onCancel?: () => void;
  onSubmit?: (values: Partial<Roaster>) => void;
  submitting?: boolean;
}

const EditShopForm: FC<Props> = ({ defaultValues = {}, onCancel, onSubmit = () => undefined, submitting = false }) => {
  const [data, setData] = useState<Partial<Roaster>>(defaultValues);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    let newVal: unknown = value;

    if (id === 'awards') newVal = value.split('\n');

    setData((prev) => ({ ...prev, [id]: newVal }));
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    onSubmit(data);
  };

  return (
    <form className="pb-24" onSubmit={handleSubmit}>
      <div className="pt-6 pl-8">
        <Breadcrumbs aria-label="breadcrumb">
          <div onClick={onCancel} className="text-xs">
            My Shop
          </div>
          <Typography className="text-xs" color="text.primary">
            Edit My shop
          </Typography>
        </Breadcrumbs>
      </div>
      <div className="w-[616px] mx-auto">
        <Typography className="font-bold text-3xl pt-[8px]">Edit My Shop</Typography>
        <Paper className="mt-[24px]">
          <div className="px-6 py-6">
            <Typography className="font-bold text-xl">ข้อมูลร้านค้า</Typography>
            <InputWithLabel
              id="name"
              label="ชื่อร้านค้า"
              required={true}
              width="w-[560px]"
              value={data.name}
              onChange={handleChange}
            />
            <FormControl className="pt-4 w-full" variant="standard">
              <FormHelperText>
                <div className="flex items-center text-black">
                  <Typography>สตอรี่ร้านค้าของคุณ</Typography> <div className="text-red-600">&nbsp;*</div>
                </div>
              </FormHelperText>
              <TextField id="description" multiline rows={9} value={data.description} onChange={handleChange} />
            </FormControl>

            <FormControl className="pt-[26px] w-full" variant="standard">
              <FormHelperText>
                <div className="flex items-center text-black">
                  <Typography>รางวัล</Typography> <div className="text-red-600">&nbsp;*</div>{' '}
                  <Typography className="text-[#60778E] text-sm">
                    โปรดพิมพ์รางวัลแล้วกดปุ่ม Enter เพื่อขึ้นบรรทัดใหม่
                  </Typography>
                </div>
              </FormHelperText>
              <TextField id="awards" multiline rows={6} value={data.awards?.join('\n')} onChange={handleChange} />
            </FormControl>
          </div>
        </Paper>
      </div>
      <Paper className="mt-16 w-full absolute bottom-0 left-0">
        <div className="w-[616px] mx-auto flex justify-between">
          <div className="flex justify-between py-[14px]">
            <Button onClick={onCancel} variant="outlined" className="rounded-full px-[70px]" disabled={submitting}>
              ยกเลิก
            </Button>
          </div>
          <div className="flex justify-between py-[14px]">
            <LoadingButton
              className="bg-blue-500 font-normal text-base rounded-full normal-case px-[69.60px]"
              variant="contained"
              type="submit"
              loading={submitting}
            >
              <Typography>บันทึก</Typography>
            </LoadingButton>
          </div>
        </div>
      </Paper>
    </form>
  );
};

export default EditShopForm;

export const ORDER_LIST = {
  DATE: 'Date',
  DELIVERED: 'Delivered',
  DELIVERY_ADDRESS: 'Delivery Address',
  GRIND: '**บด',
  LABEL: 'Label',
  LATE: 'Late',
  NOT_GRIND: 'ไม่บด',
  PARCEL: {
    PARCEL_NAME: 'parcelSize'
  },
  PARCEL_OPTION: [
    'Box A',
    'Box B',
    'Box C'
  ],
  PARCEL_SIZE: 'Parcel Size',
  POSTAL: 'Postal',
  PRINT_LABEL: 'Print Label',
  PRODUCTS: 'Products',
  STATUS: 'Status',
  TITLE: 'Orders',
  TOTAL: 'Total'
};

export const ORDER_DETAIL = {
  ALERT_INFO: 'คัดลอกเรียบร้อยแล้ว',
  COPY: 'คัดลอกทั้งหมด',
  DISCOUNT: 'Discount',
  GRINDING: 'Grinding',
  LABEL: 'รายการของ',
  ORDER_ID: 'Order ID',
  ORDER_TIMESTAMP: 'Order Timestamp',
  PAID_TIMESTAMP: 'Paid Timestamp',
  PAYMENT_METHOD: 'Payment Method',
  PRODUCT: 'Product',
  QUANTITY: 'Quantity',
  SHIPPING_ADDRESS: 'Shipping Address',
  STATUS: 'Status',
  SUBTOTAL: 'Subtotal',
  SUPPLIER: 'Supplier',
  TITLE: 'All Details',
  TOTAL: 'Total',
  USER: 'User',
  VAT: 'VAT',
};

export const ORDER_LABEL = {
  DOWNLOAD: 'ดาวน์โหลด',
  LABEL: 'ฉลากพัสดุของ',
  ORDER_ID: 'Order ID',
  TITLE: 'Label'
};

export const ORDER_POSTAL = {
  DELIVERY: 'Delivery',
  ORDERS: 'Orders',
  POSTAL: {
    COURIER: {
      ID: 'shippingCourier',
      LABEL: 'เลือกไปรษณีย์จัดส่ง',
      OPTIONS: [
        'Flash Express',
        'Kerry',
        'J&T Express',
        'Thai Post',
        'Best Express',
        'Ninja Van',
        'Nim Express',
        'Shopee Express',
        'Others'
      ],
      OTHER: {
        ID: 'shippingCourierOther',
        LABEL: 'อื่นๆโปรดระบุ'
      },
      TITLE: 'ไปรษณีย์ที่ร้านค้าเลือกจัดส่งเอง'
    },
    SHIPPING_DETAIL: {
      DATE:{
        ID: 'shippingDate',
        LABEL: 'วันที่'
      },
      TIME:{
        ID: 'shippingTime',
        LABEL: 'เวลา'
      },
      TITLE: 'กรอกข้อมูลพัสดุ',
      TRACKING:{
        ID: 'shipmentTrackingNumber',
        LABEL: 'เลขติดตามพัสดุ'
      },
      
    }
  }
};

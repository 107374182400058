/* eslint-disable no-process-env */

export const GCLOUD_PROJECT = process.env.REACT_APP_GCLOUD_PROJECT;

export const env = process.env.REACT_APP_env;

export const isLocal = env === 'local';

export const isDev = isLocal || env === 'dev';

export const isProd = env === 'prod';

export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;

export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;

export const FIREBASE_SENDER_ID = process.env.REACT_APP_FIREBASE_SENDER_ID;

export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;

export const firebaseConfig = isLocal
  ? {
      apiKey: 'key',
      authDomain: `${GCLOUD_PROJECT}.firebaseapp.com`,
      projectId: GCLOUD_PROJECT,
      storageBucket: 'default-bucket',
    }
  : {
      apiKey: FIREBASE_API_KEY,
      appId: FIREBASE_APP_ID,
      authDomain: `${FIREBASE_PROJECT_ID}.firebaseapp.com`,
      messagingSenderId: FIREBASE_SENDER_ID,
      projectId: FIREBASE_PROJECT_ID,
      storageBucket: `${FIREBASE_PROJECT_ID}.appspot.com`,
    };

/* eslint-disable sort-keys-fix/sort-keys-fix */
import { AdditionalColumnDelegate, buildEntityCallbacks, buildSchema, EntityReference } from '@camberi/firecms';
import { collection, deleteField, getDocs, getFirestore, query, where } from 'firebase/firestore';

export enum CouponDiscountType {
  'Absolute' = 'Absolute',
  'Percent' = 'Percent',
}

interface CouponRestrictions {
  usageLimit?: number;
  allowedRoasters?: EntityReference[];
  blockedRoasters?: EntityReference[];
  maxDiscountAmount?: number;
  minOrderValue?: number;
}

interface Coupon {
  name: string;
  active: boolean;
  discountType: CouponDiscountType;
  discountAmount: number;
  startDate: Date;
  endDate: Date;
  restrictions?: CouponRestrictions;
  usages: number;
  reservations: number;
}

type CouponSchema = Omit<Coupon, 'usages' | 'reservations'>;

const getMinOrderValue = (discountType?: CouponDiscountType, discountAmount?: number): number | undefined => {
  if (!(discountType && typeof discountAmount === 'number')) return undefined;

  const minTotalvalue = 20;

  switch (discountType) {
    case 'Absolute':
      return discountAmount + minTotalvalue;
    case 'Percent':
      return discountAmount === 100 ? undefined : (100 * minTotalvalue) / (100 - discountAmount);

    default:
      return undefined;
  }
};

export const CouponSchema = buildSchema<CouponSchema>({
  defaultValues: { active: false, usages: 0 },
  name: 'Coupon',
  properties: {
    active: {
      dataType: 'boolean',
      title: 'Active',
      validation: { required: true },
    },
    name: {
      dataType: 'string',
      title: 'Coupon Name',
      validation: { required: true, trim: true },
    },
    discountType: {
      config: { enumValues: CouponDiscountType },
      dataType: 'string',
      title: 'Discount Type',
      validation: { required: true },
    },
    discountAmount: ({ values }) => ({
      dataType: 'number',
      title: `Discount Amount${
        values.discountType ? ` (${values.discountType === 'Absolute' ? 'Baht' : 'Percent'})` : ''
      }`,
      validation: { min: 0, required: true, ...(values.discountType === 'Percent' && { max: 100 }) },
    }),
    startDate: ({ values }) => ({
      dataType: 'timestamp',
      title: 'Start Date',
      validation: { required: true, max: values.endDate },
    }),
    endDate: ({ values }) => ({
      dataType: 'timestamp',
      title: 'End Date',
      validation: { required: true, min: values.startDate },
    }),
    restrictions: ({ values }) => ({
      dataType: 'map',
      title: 'Restrictions',
      properties: {
        allowedRoasters: {
          dataType: 'array',
          of: {
            dataType: 'reference',
            path: 'roasters',
          },
          title: 'Allowed Roasters',
        },
        blockedRoasters: {
          dataType: 'array',
          of: {
            dataType: 'reference',
            path: 'roasters',
          },
          title: 'Blocked Roasters',
        },
        maxDiscountAmount: {
          dataType: 'number',
          title: 'Maximum Discount Amount',
          validation: { min: 1 },
        },
        minOrderValue: {
          dataType: 'number',
          title: 'Minimum Order Value',
          validation: {
            min: getMinOrderValue(values.discountType, values.discountAmount),
          },
        },
        usageLimit: {
          dataType: 'number',
          title: 'Usage Limit',
          validation: { min: 1 },
        },
      },
    }),
  },
});

export const usagesColumnDelegate: AdditionalColumnDelegate<CouponSchema> = {
  id: 'usages',
  title: 'Usages',
  builder: ({ entity }) => (entity.values as Coupon).usages || 0,
};

export const reservationsColumnDelegate: AdditionalColumnDelegate<CouponSchema> = {
  id: 'reservations',
  title: 'Reservations',
  builder: ({ entity }) => (entity.values as Coupon).reservations || 0,
};

const validateCouponRange = async ({
  values,
  path,
  entityId,
}: {
  values: Partial<CouponSchema>;
  path: string;
  entityId?: string;
}) => {
  // Check if end date shouldn't end before start date
  const startDate = new Date(values.startDate || 0);
  const endDate = new Date(values.endDate || 0);

  if (startDate > endDate) throw new Error('End date cannot be later than start date');

  // Check is a new coupon have start and end date overlap with existing coupons
  const db = getFirestore();
  const couponRef = collection(db, path);
  const snapshots = await getDocs(query(couponRef, where('name', '==', values.name)));

  if (!snapshots.empty) {
    snapshots.forEach((snapshot) => {
      const data = snapshot.data();

      if (values.name === data.name && snapshot.id !== entityId) {
        const snapshotStartDate = new Date(data.startDate.seconds * 1000 || 0);
        const snapshotEndDate = new Date(data.endDate.seconds * 1000 || 0);
        if (snapshotStartDate <= endDate && snapshotEndDate >= startDate) {
          throw new Error('Cannot have coupon on the same range');
        }
      }
    });
  }
};

export const couponCallbacks = buildEntityCallbacks<CouponSchema>({
  onPreSave: async ({ values, path, entityId }) => {
    await validateCouponRange({ values, path, entityId });

    const minOrderValue = getMinOrderValue(values.discountType, values.discountAmount);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const coupon: any = {
      ...values,
      restrictions: {
        ...(values.restrictions || {}),
        ...(minOrderValue && !values.restrictions?.minOrderValue && { minOrderValue }),
        ...(values.restrictions?.maxDiscountAmount &&
          values.discountType === 'Absolute' && { maxDiscountAmount: deleteField() }),
      },
    };

    return coupon;
  },
});

import { Entity, useDataSource, useSnackbarController } from '@camberi/firecms';
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { doc, getFirestore, Timestamp, updateDoc } from 'firebase/firestore';
import React, { FC, useEffect, useMemo, useState } from 'react';

import useAsyncEffect from '../../hooks/useAsyncEffect';
import { Order, OrderSchema, SAMPLE_ITEMS } from '../../schemas/order.schema';
import { Roaster, roasterSchema } from '../../schemas/roaster.schema';
import formatPrice from '../../utils/formatPrice';
import ShipmentManagementModal from './ShipmentManagementModal';

interface Data {
  supplier: string;
  type: string;
  quantity: number;
  details: string;
  price: number;
  shippingInfo: string;
  productType: string;
  ground: string;
  ticketIdx: number;
  shippedTimestamp?: Date;
  shippingCourier?: string;
  shipmentTrackingNumber?: string;
  deliveryComplete?: boolean;
  giftMessage: string;
}

const productTypesMap = {
  'coffee-beans': 'Coffee Beans',
  'cold-brew': 'Cold Brew',
  'drip-bag': 'Drip Bag',
};

interface Props {
  order: Entity<OrderSchema>;
  refresh?: () => void;
}

// eslint-disable-next-line max-lines-per-function
const SingleOrderManagement: FC<Props> = ({ order, refresh = () => undefined }) => {
  const {
    orderTimestamp,
    user,
    shippingAddress,
    subtotal,
    vat,
    discount,
    shipping,
    total,
    paymentMethod,
    paidTimestamp,
    tickets,
    status,
  } = order.values as Order;

  const snackbarController = useSnackbarController();
  const { fetchEntity } = useDataSource();
  const [open, setOpen] = useState(false);
  const [shipment, setShipment] = useState<{
    ticketIdx: number;
    currentValues: { timestamp?: Date; courier?: string; trackingNumber?: string; complete?: boolean };
    hideCourier: boolean;
  } | null>(null);
  const {
    data: roasters,
    loading: roastersLoading,
    error: roastersError,
  } = useAsyncEffect<Entity<Roaster>[]>(() => {
    const roasterIds = Array.from(
      new Set(
        tickets
          .filter((ticket) => ticket.items[0].type === 'Product')
          .flatMap((ticket) => ticket.items)
          .map((item) => item?.productRefs[0]?.path?.split('/')[1])
          .filter(Boolean) as string[],
      ),
    );

    return Promise.all(
      roasterIds.map((entityId) => fetchEntity({ entityId, path: 'roasters', schema: roasterSchema })),
    );
  }, [tickets]);
  const tableData = useMemo<Data[] | null>(() => {
    if (roastersLoading) return null;

    const data: Data[] = [];

    tickets.forEach((ticket, ticketIdx) => {
      const firstItem = ticket.items[0];

      data.push({
        deliveryComplete: ticket.deliveryComplete,
        details: '',
        giftMessage: '',
        ground: '',
        price: 0,
        productType: '',
        quantity: 0,
        shipmentTrackingNumber: ticket.shipmentTrackingNumber,
        shippedTimestamp:
          ticket.shippedTimestamp instanceof Date
            ? ticket.shippedTimestamp
            : (ticket.shippedTimestamp as unknown as Timestamp)?.toDate(),
        shippingCourier: ticket.shippingCourier,
        shippingInfo: `Timestamp: ${
          (ticket.shippedTimestamp instanceof Date
            ? ticket.shippedTimestamp
            : (ticket.shippedTimestamp as unknown as Timestamp)?.toDate()) || ''
        }\nCourier: ${ticket.shippingCourier || ''}\nTracking Number: ${
          ticket.shipmentTrackingNumber || ''
        }\nDelivery Complete: ${ticket.deliveryComplete ? 'Yes (ใช่)' : 'No (ไม่)'}`,
        supplier: SAMPLE_ITEMS.includes(firstItem.type)
          ? 'KOPH'
          : roasters?.find((roaster) => roaster.id === firstItem.productRefs[0].path?.split('/')[1])?.values.name ||
            'Unknown',
        ticketIdx,
        type: '',
      });

      ticket.items.forEach((item) => {
        data.push({
          details:
            item.type === 'Product'
              ? item.products[0]?.name || 'Unknown'
              : Object.entries((item.metadata?.boxQuantities as Record<string, number>) || {})
                  .map(
                    ([path, quantity]) =>
                      `x${quantity} - ${
                        item.products.find((product) => product.ref.pathWithId === path)?.name || 'Unknown'
                      }`,
                  )
                  .join('\n'),
          giftMessage: item.metadata?.giftMessage as string,
          ground: item.metadata?.grounded ? (item.metadata.brewMethod as string) || 'Yes' : 'No',
          price: item.unitPrice * item.quantity,
          productType: productTypesMap[item.metadata?.productType as keyof typeof productTypesMap] || 'Coffee Beans',
          quantity: item.quantity,
          shippingInfo: '',
          supplier: '',
          ticketIdx,
          type: item.type === 'Product' ? 'Single Purchase' : item.type,
        });
      });
    });

    return data;
  }, [roasters, roastersLoading, tickets]);

  useEffect(() => {
    if (roastersError) {
      console.error('Error fetching roasters.', roastersError);
      snackbarController.open({ message: 'Error fetching roasters.', type: 'error' });
    }
  }, [roastersError, snackbarController]);

  useEffect(() => {
    if (tickets.every((ticket) => ticket.shippedTimestamp) && status === 'Processed') {
      const db = getFirestore();
      const orderRef = doc(db, order.path, order.id);
      updateDoc(orderRef, { status: 'Shipped' }).then(() => {
        refresh();
        snackbarController.open({ message: 'Status updated to "Shipped".', type: 'success' });
      });
    } else if (tickets.every((ticket) => ticket.deliveryComplete) && status !== 'Completed') {
      const db = getFirestore();
      const orderRef = doc(db, order.path, order.id);
      updateDoc(orderRef, { status: 'Completed' }).then(() => {
        refresh();
        snackbarController.open({ message: 'Status updated to "Completed".', type: 'success' });
      });
    }
  }, [order.id, order.path, refresh, snackbarController, status, tickets]);

  const details: { label: string; value: string }[] = [
    { label: 'Order Timestamp', value: orderTimestamp.toString() },
    { label: 'Status', value: status },
    { label: 'User', value: `${user.firstName}${user.lastName ? ` ${user.lastName}` : ''} (${user.email})` },
    {
      label: 'Shipping Address',
      value: `${shippingAddress.firstName} ${shippingAddress.lastName}\n${shippingAddress.address}\n${shippingAddress.subdistrict}\n${shippingAddress.district}, ${shippingAddress.province}\n${shippingAddress.zipCode}\n${shippingAddress.phoneNumber}`,
    },
    { label: 'Subtotal', value: formatPrice(subtotal) },
    { label: 'VAT', value: formatPrice(vat) },
    { label: 'Discount', value: formatPrice(discount) },
    { label: 'Shipping', value: formatPrice(shipping) },
    { label: 'Total', value: formatPrice(total) },
    { label: 'Payment Method', value: paymentMethod },
    { label: 'Paid Timestamp', value: paidTimestamp.toString() },
  ];

  return (
    <>
      <Box>
        <Typography variant="h5">
          Order ID: <span className="font-semibold">{order.id}</span>
        </Typography>
        <Box className="mt-8 flex flex-col space-y-4">
          {details.map(({ label, value }) => (
            <Box key={label}>
              <Typography variant="h6" className="underline font-medium">
                {label}:
              </Typography>
              <Typography variant="h6" className="font-normal whitespace-pre">
                {value}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box className="mt-8">
          <Typography variant="h6">Tickets</Typography>
          <TableContainer className="mt-4">
            <Table sx={{ minWidth: 650 }} aria-label="tickets table">
              <TableHead>
                <TableRow>
                  <TableCell>Supplier</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">Details</TableCell>
                  <TableCell align="right">Price</TableCell>
                  <TableCell align="right">Shipping Info</TableCell>
                  <TableCell align="right">Product Type</TableCell>
                  <TableCell align="right">Gift Message</TableCell>
                  <TableCell align="right">Grinding</TableCell>
                  <TableCell align="right"> &nbsp;</TableCell>
                </TableRow>
              </TableHead>
              {!tableData ? (
                <Box className="flex justify-center items-center py-4">
                  <CircularProgress />
                </Box>
              ) : (
                <TableBody>
                  {tableData.map((row, idx) => (
                    <TableRow
                      key={row.supplier || row.details}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" className={tableData[idx + 1]?.supplier ? '' : 'border-0'}>
                        {row.supplier}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.type}
                      </TableCell>
                      <TableCell align="right">{row.quantity || ''}</TableCell>
                      <TableCell align="right" className="whitespace-pre">
                        {row.details}
                      </TableCell>
                      <TableCell className="whitespace-nowrap" align="right">
                        {row.price ? formatPrice(row.price) : ''}
                      </TableCell>
                      <TableCell align="right" className="whitespace-pre">
                        {row.shippingInfo}
                      </TableCell>
                      <TableCell align="right" className="whitespace-pre">
                        {row.productType}
                      </TableCell>
                      <TableCell align="right" className="whitespace-pre">
                        {row.giftMessage}
                      </TableCell>
                      <TableCell align="right" className="whitespace-pre">
                        {row.ground}
                      </TableCell>
                      <TableCell align="right" className={tableData[idx + 1]?.supplier ? '' : 'border-0'}>
                        {row.supplier && (
                          <Button
                            onClick={() => {
                              setShipment({
                                currentValues: {
                                  complete: row.deliveryComplete,
                                  courier: row.shippingCourier,
                                  timestamp: row.shippedTimestamp,
                                  trackingNumber: row.shipmentTrackingNumber,
                                },
                                hideCourier: row.supplier === 'KOPH',
                                ticketIdx: row.ticketIdx,
                              });
                              setOpen(true);
                            }}
                          >
                            Manage Shipping
                          </Button>
                        )}{' '}
                        &nbsp;
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <ShipmentManagementModal
        open={open}
        onClose={() => {
          setOpen(false);
          setShipment(null);
        }}
        {...shipment}
        order={order}
        refresh={refresh}
      />
    </>
  );
};

export default SingleOrderManagement;

import { Entity, useDataSource } from '@camberi/firecms';
import { Breadcrumbs, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';

import { ProductSchema, productSchema, RoastLevel } from '../../../../schemas/product.schema';
import { Roaster } from '../../../../schemas/roaster.schema';
import ProductForm from './ProductForm';

interface Props {
  roaster: Entity<Roaster> | undefined;
  onUpdated: () => void;
  onCancel: (value: boolean) => void;
}

const defaultValues = {
  additions: [],
  altitudeMeters: 0,
  awards: [],
  brewMethods: [],
  description: '',
  externalLink: '',
  forSale: false,
  groundable: false,
  image: '',
  inStock: false,
  name: '',
  origins: [
    {
      city: '',
      country: '',
    },
  ],
  process: '',
  published: false,
  quantityOptions: [{ grams: 0, price: 0, stock: 0 }],
  roastLevel: RoastLevel.Light,
  roastedDate: {} as Date,
  roaster: {
    description: '',
    logo: '',
    name: '',
    slug: '',
  },
  samplePrice: 0,
  slug: '',
  tasteDescription: '',
  tasteNotes: [],
  tasteQualities: {
    acidity: 1,
    body: 1,
    sweetness: 1,
  },
  varieties: [],
};

const ProductAdd: FC<Props> = ({ roaster, onUpdated, onCancel }) => {
  const { saveEntity } = useDataSource();
  const [product, setProduct] = useState<Entity<ProductSchema>>();

  useEffect(() => {
    saveEntity({
      entityId: new Date().getTime().toString(),
      path: `roasters/${roaster?.id}/products`,
      schema: productSchema,
      status: 'new',
      values: defaultValues,
    }).then((entity) => {
      setProduct(entity);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roaster?.id]);

  return (
    <div>
      <div className="pt-6 pl-8">
        <Breadcrumbs aria-label="breadcrumb">
          <a className="text-xs no-underline cursor-pointer" color="inherit" onClick={() => onCancel(true)}>
            My Products
          </a>
          <Typography className="text-xs" color="text.primary">
            Add product
          </Typography>
        </Breadcrumbs>
      </div>
      <ProductForm roaster={roaster} product={product} onUpdated={onUpdated} onClose={() => onCancel(true)} />
    </div>
  );
};

export default ProductAdd;

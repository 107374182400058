/* eslint-disable max-lines-per-function */
import { Entity, useDataSource } from '@camberi/firecms';
import { CircularProgress } from '@mui/material';
import { FC, useEffect, useState } from 'react';

import useAsyncEffect from '../../../hooks/useAsyncEffect';
import { getProductStats, ProductSchema, productSchema } from '../../../schemas/product.schema';
import { Roaster } from '../../../schemas/roaster.schema';
import ProductAdd from '../components/product/ProductAdd';
import ProductEdit from '../components/product/ProductEdit';
import ProductList from '../components/product/ProductList';

interface Props {
  roaster: Entity<Roaster>;
}

const MyProductsPage: FC<Props> = ({ roaster }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [productStats, setProductStats] = useState({} as Record<string, any | null>);
  const [selectedProduct, setSelectedProduct] = useState<Entity<ProductSchema> | null>(null);
  const [onAddProduct, setOnAddProduct] = useState<boolean>(false);
  const { fetchCollection } = useDataSource();
  const {
    data: productsData,
    loading: productsLoading,
    error: productsError,
  } = useAsyncEffect(
    () => fetchCollection({ path: `roasters/${roaster.id}/products`, schema: productSchema }),
    [fetchCollection],
  );
  const products: Entity<ProductSchema>[] = productsData || [];

  useEffect(() => {
    if (productsError) console.error('Error fetching products.', productsError);
  }, [productsError]);

  const fetchProducts = async () => {
    products?.forEach(async (product: Entity<ProductSchema>) => {
      const stats = await getProductStats(product);
      productStats[product.id] = stats;
      setProductStats({ ...productStats });
    });
  };

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  if (productsLoading)
    return (
      <div>
        <CircularProgress />
      </div>
    );

  const handleOnAddProduct = (value: boolean) => {
    setOnAddProduct(value);
  };

  const handleOnEditProduct = (productId: string) => {
    const product = products?.find((product) => product.id === productId);

    if (product) {
      setSelectedProduct(product);
    }
  };

  return (
    <>
      {onAddProduct ? (
        <ProductAdd roaster={roaster} onUpdated={fetchProducts} onCancel={() => setOnAddProduct(false)} />
      ) : products && selectedProduct ? (
        <ProductEdit
          roaster={roaster}
          product={selectedProduct}
          onUpdated={fetchProducts}
          onClose={() => setSelectedProduct(null)}
        />
      ) : (
        <ProductList onAddProduct={handleOnAddProduct} onEditProduct={handleOnEditProduct} roaster={roaster} />
      )}
    </>
  );
};

export default MyProductsPage;

import { DateRange } from '@mui/lab';
import {
  collection,
  doc,
  DocumentSnapshot,
  getDoc,
  getFirestore,
  Query,
  query,
  QueryDocumentSnapshot,
  Timestamp,
  where,
} from 'firebase/firestore';
import get from 'lodash.get';
import { useCallback } from 'react';

import { Order } from '../schemas/order.schema';
import { Product } from '../schemas/product.schema';
import getAllDocs from '../utils/getAllDocs';

export type ExportOrders = (range?: DateRange<Date>) => Promise<string[][]>;

export type UseOrderExport = () => { exportOrders: ExportOrders };

type ModelType = 'order' | 'product' | 'item';

const getOrdersInRange = async (range?: DateRange<Date>): Promise<QueryDocumentSnapshot<Order>[]> => {
  const db = getFirestore();
  const ordersRef = collection(db, 'orders');
  let ordersQuery = query(ordersRef);

  if (range?.[0]) ordersQuery = query(ordersQuery, where('orderTimestamp', '>=', range[0]));
  if (range?.[1]) ordersQuery = query(ordersQuery, where('orderTimestamp', '<=', range[1]));

  return getAllDocs(ordersQuery as Query<Order>);
};

const getOrderProducts = async (orders: QueryDocumentSnapshot<Order>[]): Promise<DocumentSnapshot<Product>[]> => {
  const db = getFirestore();
  const productPaths = Array.from(
    new Set(
      orders.flatMap((doc) =>
        doc
          .data()
          ?.tickets?.flatMap((ticket) =>
            ticket?.items?.filter((item) => item.type === 'Product')?.flatMap((item) => item.productRefs),
          )
          ?.map((ref) => ref.path),
      ),
    ),
  );
  const products = (await Promise.all(productPaths.map((path) => getDoc(doc(db, path))))).filter(
    (doc) => doc.exists,
  ) as DocumentSnapshot<Product>[];

  return products;
};

const dataList: { header: string; model: ModelType; key: string }[] = [
  { header: 'orderTimestamp', key: 'orderTimestamp', model: 'order' },
  { header: 'productName', key: 'name', model: 'product' },
  { header: 'roasterName', key: 'roaster.name', model: 'product' },
  { header: 'quantity', key: 'quantity', model: 'item' },
  { header: 'quantityGrams', key: 'metadata.quantityGrams', model: 'item' },
  { header: 'grounded', key: 'metadata.grounded', model: 'item' },
  { header: 'brewMethod', key: 'metadata.brewMethod', model: 'item' },
  { header: 'userFirstName', key: 'user.firstName', model: 'order' },
  { header: 'userLastName', key: 'user.lastName', model: 'order' },
  { header: 'userEmail', key: 'user.email', model: 'order' },
  { header: 'shippingAddress_firstName', key: 'shippingAddress.firstName', model: 'order' },
  { header: 'shippingAddress_lastName', key: 'shippingAddress.lastName', model: 'order' },
  { header: 'shippingAddress_address', key: 'shippingAddress.address', model: 'order' },
  { header: 'shippingAddress_subdistrict', key: 'shippingAddress.subdistrict', model: 'order' },
  { header: 'shippingAddress_district', key: 'shippingAddress.district', model: 'order' },
  { header: 'shippingAddress_province', key: 'shippingAddress.province', model: 'order' },
  { header: 'shippingAddress_zipCode', key: 'shippingAddress.zipCode', model: 'order' },
  { header: 'shippingAddress_country', key: 'shippingAddress.country', model: 'order' },
  { header: 'shippingAddress_phoneNumber', key: 'shippingAddress.phoneNumber', model: 'order' },
];

const pullData = (
  model: ModelType,
  key: string,
  models: {
    order: DocumentSnapshot<Order>;
    product?: DocumentSnapshot<Product>;
    item: Order['tickets']['0']['items'][0];
  },
): string => {
  let data: unknown = '';

  if (!models[model]) return 'unknown';

  if (model == 'item') data = get(models.item, key);
  else {
    if (!models[model]?.exists) return 'unknonwn';

    data = get(models[model]?.data() || {}, key);
  }

  if (!data) return '';
  if (typeof data === 'number' || typeof data === 'boolean') return `${data}`;
  if ((data as object)?.toString().startsWith('Timestamp(seconds')) return (data as Timestamp).toDate().toUTCString();

  return data as string;
};

const arrayToCsv = (data: string[][]): string => {
  return data
    .map(
      (row) =>
        row
          .map(String) // convert every value to String
          .map((v) => v.replaceAll('"', '""')) // escape double colons
          .map((v) => `"${v}"`) // quote it
          .join(','), // comma-separated
    )
    .join('\r\n'); // rows starting on new lines
};

// eslint-disable-next-line max-lines-per-function
const useOrderExport: UseOrderExport = () => {
  const downloadBlob = useCallback((content, filename, contentType) => {
    // Create a blob
    const blob = new Blob([content], { type: contentType });
    const url = URL.createObjectURL(blob);

    // Create a link to download it
    const pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', filename);
    pom.click();
  }, []);

  const exportOrders: ExportOrders = useCallback(
    async (range) => {
      const orders = await getOrdersInRange(range);
      const products = await getOrderProducts(orders);
      const orderData: string[][] = [];
      const headerRow = [...dataList.map((row) => row.header), 'productPath', 'roasterPath', 'orderPath'];

      orders.forEach((order) => {
        const { tickets } = order.data();

        if (!tickets?.length) return;

        tickets
          .flatMap((ticket) => ticket.items)
          .filter((item) => item.type === 'Product')
          .forEach((item) => {
            const productPath = item.productRefs[0].path;
            const product = products.find((doc) => doc.ref.path === productPath);

            orderData.push([
              ...dataList.map(({ model, key }) => pullData(model, key, { item, order, product })),
              product?.ref?.path || '',
              product?.ref?.parent?.parent?.path || '',
              order.ref.path,
            ]);
          });
      }, []);

      const rowData = [headerRow, ...orderData];
      const csvData = arrayToCsv(rowData);

      downloadBlob(csvData, 'export.csv', 'text/csv;charset=utf-8;');

      return rowData;
    },
    [downloadBlob],
  );

  return { exportOrders, getOrdersInRange };
};

export default useOrderExport;

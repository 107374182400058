import { Entity } from '@camberi/firecms';
import { Typography } from '@mui/material';
import { FC, useState } from 'react';

import { OrderDocument } from '../../../interfaces/Firestore';
import { Roaster } from '../../../schemas/roaster.schema';
import OrderAllDetail from '../components/order/OrderAllDetail';
import OrderLabel from '../components/order/OrderLabel';
import OrderList, { OrderDetail } from '../components/order/OrderList';
import OrderPostal from '../components/order/OrderPostal';

interface Props {
  roaster: Entity<Roaster>;
}
const defaultCurrentForm = 'Order List';
const MyOrdersPage: FC<Props> = ({ roaster }) => {
  const [selectedOrder, setSelectedOrder] = useState<OrderDocument>();
  const [orderDetail, setOrderDetail] = useState<OrderDetail>();
  const [currentForm, setCurrentForm] = useState<string>(defaultCurrentForm);

  if (!roaster) return <Typography>Roaster Data Missing.</Typography>;

  const handleOnSelectedRow = (value: string, selectedRow: OrderDocument, orderDetail: OrderDetail) => {
    setSelectedOrder(selectedRow);
    setOrderDetail(orderDetail);
    setCurrentForm(value);
  };

  return (
    <>
      {currentForm === 'Label' && (
        <OrderLabel
          order={selectedOrder}
          onOpen={true}
          orderDetail={orderDetail}
          onClose={() => setCurrentForm(defaultCurrentForm)}
        />
      )}
      {currentForm === 'Detail' && (
        <OrderAllDetail
          order={selectedOrder}
          roaster={roaster}
          onOpen={true}
          orderDetail={orderDetail}
          onClose={() => setCurrentForm(defaultCurrentForm)}
        />
      )}
      {currentForm === 'Postal' ? (
        <OrderPostal
          order={selectedOrder}
          orderDetail={orderDetail}
          onClose={() => setCurrentForm(defaultCurrentForm)}
        />
      ) : (
        <OrderList handleOnClick={handleOnSelectedRow} roaster={roaster} />
      )}
    </>
  );
};

export default MyOrdersPage;

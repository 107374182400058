import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../styles/blogEditor.css';

import { FieldProps } from '@camberi/firecms';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { FC, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';

export const BlogContentField: FC<FieldProps<string>> = ({ value, setValue }) => {
  let contentState: ContentState;
  if (value) {
    const blocksFromHtml = htmlToDraft(value);
    const { contentBlocks, entityMap } = blocksFromHtml;
    contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  }

  const [editorState, setEditorState] = useState(() =>
    contentState ? EditorState.createWithContent(contentState) : EditorState.createEmpty(),
  );

  const onChange = (currentState: EditorState) => {
    setEditorState(currentState);
    const html = draftToHtml(convertToRaw(currentState.getCurrentContent()));
    setValue(html);
  };
  return (
    <div className="Blog Editor">
      <Editor
        defaultEditorState={editorState}
        onEditorStateChange={onChange}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        toolbar={{
          blockType: {
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            inDropdown: true,
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
          },
          image: {
            alt: { mandatory: true, present: true },
          },
          options: ['inline', 'image', 'blockType', 'list', 'link', 'textAlign'],
        }}
      />
    </div>
  );
};

import { PreviewComponentProps } from '@camberi/firecms';
import { convertFromHTML } from 'draft-convert';
import { Editor, EditorState } from 'draft-js';
import React, { ReactElement } from 'react';

export const BlogContentPreview = ({ value }: PreviewComponentProps<string>): ReactElement => {
  const editorState = EditorState.createWithContent(convertFromHTML(value));
  return (
    <div>
      <Editor editorState={editorState} readOnly={true} onChange={() => {}} />
    </div>
  );
};

import { Entity } from '@camberi/firecms';
import { Chip, CircularProgress, Radio } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, FC, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { Order, OrderSchema, SAMPLE_ITEMS } from '../../schemas/order.schema';
import formatPrice from '../../utils/formatPrice';

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric?: boolean;
}

interface Data {
  id: string;
  timestamp: string;
  status: string;
  total: number;
  user: string;
  sampleBox: boolean;
  singlePurchase: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    disablePadding: true,
    id: 'id',
    label: 'Order ID',
  },
  {
    disablePadding: false,
    id: 'timestamp',
    label: 'Timestamp',
  },
  {
    disablePadding: true,
    id: 'status',
    label: 'Status',
  },
  {
    disablePadding: false,
    id: 'total',
    label: 'Total',
    numeric: true,
  },
  {
    disablePadding: false,
    id: 'user',
    label: 'User',
  },
  {
    disablePadding: false,
    id: 'samplebox',
    label: 'Samplebox',
  },
  {
    disablePadding: false,
    id: 'singlepurchase',
    label: 'Single Purchase',
  },
];

const pageOptions = [10, 25, 50];

interface Props {
  orders?: Entity<OrderSchema>[] | null;
  loading?: boolean;
}

// eslint-disable-next-line max-lines-per-function
const OrderManagementTable: FC<Props> = ({ orders, loading }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pageOptions[0]);
  const rows = useMemo<Data[]>(
    () =>
      orders?.map(({ id, values }) => ({
        id,
        sampleBox: (values as Order).tickets.some((ticket) =>
          ticket.items.some((item) => SAMPLE_ITEMS.includes(item.type)),
        ),
        singlePurchase: (values as Order).tickets.some((ticket) =>
          ticket.items.some((item) => item.type === 'Product'),
        ),
        status: values.status,
        timestamp: (values.orderTimestamp as Date).toString(),
        total: values.total,
        user: `${(values as Order).user.firstName}${
          (values as Order).user.lastName ? ` ${(values as Order).user.lastName}` : ''
        }`,
      })) || [],
    [orders],
  );
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ mb: 2, width: '100%' }}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { sm: 1, xs: 1 },
          }}
        >
          <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
            All Orders
          </Typography>
        </Toolbar>
        <TableContainer>
          {loading ? (
            <Box className="flex justify-center items-center py-4">
              <CircularProgress />
            </Box>
          ) : (
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox" />
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? 'right' : 'left'}
                      padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  const isItemSelected = params.orderId === row.id;
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={() => navigate(`/order-management/${row.id}`)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Radio
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.id}
                      </TableCell>
                      <TableCell align="right">{row.timestamp}</TableCell>
                      <TableCell align="right">{row.status}</TableCell>
                      <TableCell align="right" className="whitespace-nowrap">
                        {formatPrice(row.total)}
                      </TableCell>
                      <TableCell align="right">{row.user}</TableCell>
                      <TableCell align="right">
                        {row.sampleBox ? (
                          <Chip label="Yes (ใช่)" color="success" variant="outlined" />
                        ) : (
                          <Chip label="No (ไม่)" color="error" variant="outlined" />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {row.singlePurchase ? (
                          <Chip label="Yes (ใช่)" color="success" variant="outlined" />
                        ) : (
                          <Chip label="No (ไม่)" color="error" variant="outlined" />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={pageOptions}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default OrderManagementTable;

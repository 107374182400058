import { Alert, Snackbar } from '@mui/material';
import { FC } from 'react';

interface IProps {
  message: string;
  type: 'success' | 'error' | 'warning' | 'info';
  open: boolean;
  onClose: () => void;
}

export const AlertFeedback: FC<IProps> = ({
  message = 'กรอกข้อมูลสำเร็จ',
  type = 'success',
  open = false,
  onClose,
}) => (
  <Snackbar
    open={open}
    anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
    autoHideDuration={1000}
    className="w-[478px]"
    onClose={onClose}
  >
    <Alert severity={type} sx={{ width: '100%' }}>
      {message}
    </Alert>
  </Snackbar>
);

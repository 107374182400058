import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FC } from 'react';

interface ISimpleSelectProps {
  name?: string;
  options: string[] | number[];
  value: string | number;
  limitTags?: number;
  onChange: (event: SelectChangeEvent<string | number>) => void;
}

export const SimpleSelect: FC<ISimpleSelectProps> = ({ name, options, value, onChange }) => {
  return (
    <Select size="small" name={name} value={value} onChange={(event) => onChange(event)}>
      {options.map((level) => (
        <MenuItem key={level} value={level}>
          {level}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SimpleSelect;

/* eslint-disable sort-keys-fix/sort-keys-fix */

import { buildSchema, EntityReference } from '@camberi/firecms';

import { OrderItemType } from './order.schema';
import { ShippingAddress } from './user.schema';

export interface CartProduct {
  ref?: EntityReference;
  name: string;
  image: string;
}

export interface CartItem {
  uid: string;
  type: OrderItemType;
  name: string;
  image: string;
  quantity: number;
  productRefs: EntityReference[];
  unitPrice: number;
  products: CartProduct[];
  metadata?: Record<string, unknown>;
}

export interface Cart {
  created: Date;
  updated: Date;
  userRef: EntityReference;
  items: CartItem[];
  shippingAddress?: ShippingAddress;
  subtotal: number;
  vat: number;
  discount: number;
  shipping: number;
  total: number;
  couponRef?: EntityReference;
}

export const cartSchema = buildSchema<Cart>({
  name: 'Cart',
  properties: {
    userRef: { dataType: 'reference', path: 'users', title: 'User Reference' },
    items: {
      dataType: 'array',
      title: 'items',
      of: {
        dataType: 'map',
        properties: {
          uid: { dataType: 'string', title: 'uid' },
          type: { dataType: 'string', title: 'type', config: { enumValues: OrderItemType } },
          name: { dataType: 'string', title: 'name' },
          image: {
            dataType: 'string',
            title: 'image',
            config: { storageMeta: { mediaType: 'image', storagePath: 'images', acceptedFiles: ['image/*'] } },
            readOnly: true,
          },
          quantity: { dataType: 'number', title: 'quantity' },
          unitPrice: { dataType: 'number', title: 'unitPrice' },
          products: {
            dataType: 'array',
            title: 'products',
            of: {
              dataType: 'map',
              properties: {
                name: { dataType: 'string', title: 'name' },
                image: {
                  dataType: 'string',
                  title: 'image',
                  config: { storageMeta: { mediaType: 'image', storagePath: 'images', acceptedFiles: ['image/*'] } },
                  readOnly: true,
                },
              },
            },
          },
          // productRefs: { dataType: 'reference', title: 'productRefs' },
        },
      },
    },
    shippingAddress: {
      dataType: 'map',
      properties: {
        title: { dataType: 'string', title: 'Title' },
        firstName: { dataType: 'string', title: 'First Name' },
        lastName: { dataType: 'string', title: 'Last Name' },
        address: { dataType: 'string', title: 'Address' },
        subdistrict: { dataType: 'string', title: 'Sub District' },
        district: { dataType: 'string', title: 'District' },
        province: { dataType: 'string', title: 'Province' },
        zipCode: { dataType: 'string', title: 'Zip Code' },
        country: { dataType: 'string', title: 'Country' },
        phoneNumber: { dataType: 'string', title: 'Phone Number' },
      },
      title: 'Shipping Address',
    },
    subtotal: { dataType: 'number', title: 'Subtotal' },
    discount: { dataType: 'number', title: 'Discount' },
    vat: { dataType: 'number', title: 'Vat' },
    shipping: { dataType: 'number', title: 'Shipping' },
    total: { dataType: 'number', title: 'Total' },
    created: { dataType: 'timestamp', title: 'created' },
    updated: { dataType: 'timestamp', title: 'updated' },
  },
});

/* eslint-disable max-lines-per-function */
import { Entity, useDataSource, useStorageSource } from '@camberi/firecms';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { ChangeEvent, FC, useEffect, useState } from 'react';

import { ProductSchema, productSchema } from '../../../../schemas/product.schema';
import { Roaster } from '../../../../schemas/roaster.schema';
import { PRODUCT_FORM } from '../../constants/product';
import { AlertFeedback } from '../AlertFeedback';
import FormFooter from '../FormFooter';
import FormSidebar from '../FormSidebar';
import ImageUploader from '../ImageUploader';
import InputLabel from '../InputLabel';
import InputWithLabel from '../InputWithLabel';
import MultiSelect from '../MultiSelect';
import SimpleSelect from '../SimpleSelect';
import Subtitle from '../Subtitle';
import Title from '../Title';

const menuItems = [
  {
    id: 'basic-info',
    label: PRODUCT_FORM.BASIC_INFO,
  },
  {
    id: 'product-attributes',
    label: PRODUCT_FORM.PRODUCT_ATTRIBUTES,
  },
  {
    id: 'roasting-note',
    label: PRODUCT_FORM.ROASTING_NOTE,
  },
  {
    id: 'selling-info',
    label: PRODUCT_FORM.SELLING_INFO.TITLE,
  },
];

interface IProps {
  roaster: Entity<Roaster> | undefined;
  product?: Entity<ProductSchema> | undefined;
  onUpdated: () => void;
  onClose: (value: boolean) => void;
}

const ProductForm: FC<IProps> = ({ roaster, product, onUpdated, onClose }) => {
  const { saveEntity } = useDataSource();
  const { getDownloadURL } = useStorageSource();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [inputValues, setInputValues] = useState<any>();
  const [imageUrl, setImageUrl] = useState<string>('');
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    if (product) {
      if (product.values.image) {
        getDownloadURL(product.values.image).then((url: string) => setImageUrl(url));
      }
      setInputValues({ ...product.values });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, name, value } = event.target;

    if (name === PRODUCT_FORM.GROUNDABLE.NAME) {
      setInputValues({ ...inputValues, [PRODUCT_FORM.GROUNDABLE.ID]: value === 'true' });
      return;
    }

    if (
      name === 'origin.country' ||
      name === 'origin.city' ||
      name === 'quantityOptions.grams' ||
      name === 'quantityOptions.stock' ||
      name === 'quantityOptions.price'
    ) {
      const [key, index, field] = id.split('.');
      const updatedInputValues = { ...inputValues };
      updatedInputValues[key][index][field] = +value;
      setInputValues(updatedInputValues);
      return;
    }

    switch (id) {
      case PRODUCT_FORM.AWARDS.ID:
        setInputValues({ ...inputValues, [id]: value.split('\n') });
        break;

      case PRODUCT_FORM.ALTITUDE_METERS.ID:
        setInputValues({ ...inputValues, [id]: +value });
        break;

      default:
        setInputValues({ ...inputValues, [id]: value });
        break;
    }
  };

  const handleOnSelect = (event: SelectChangeEvent<string | number>) => {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  };

  const handleOnSelectTasteQualities = (event: SelectChangeEvent<string | number>) => {
    setInputValues({
      ...inputValues,
      tasteQualities: { ...inputValues.tasteQualities, [event.target.name]: event.target.value },
    });
  };

  const handleOnAddEntry = (key: string, entry: Record<string, unknown>) => {
    const entries = inputValues[key];
    const updatedEntries = [...entries, entry];
    setInputValues({ ...inputValues, [key]: updatedEntries });
  };

  const handleOnRemoveEntry = (key: string, index: number) => {
    const entries = inputValues[key];
    const updatedEntries = [...entries];
    updatedEntries.splice(index, 1);
    setInputValues({ ...inputValues, [key]: updatedEntries });
  };

  const handleOnImageUploaded = async (path: string) => {
    getDownloadURL(path).then((url: string) => setImageUrl(url));
    setInputValues({ ...inputValues, image: path });
  };

  const publish = (publish: boolean) => {
    if (product && roaster) {
      const updatedValues = {
        ...inputValues,
        altitudeMeters: +inputValues.altitudeMeters,
        published: publish,
      };
      const updatedProduct = { ...product, values: updatedValues };

      saveEntity({
        entityId: product?.id,
        path: `roasters/${roaster?.id}/products`,
        schema: productSchema,
        status: 'existing',
        values: updatedProduct.values,
      });

      setOpenAlert(true);
      onUpdated();
    }
  };

  const handleOnCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
    handleOnClose();
  };

  const handleOnSaveAsdraft = () => {
    publish(false);
  };

  const handleOnPublish = () => {
    publish(true);
  };

  const handleOnClose = () => {
    onClose(true);
  };

  return (
    <main>
      <div className="flex">
        <div className="flex-1"></div>
        <div className="w-[616px]">
          <AlertFeedback open={openAlert} message="บันทึกข้อมูลสำเร็จ" type="success" onClose={handleOnCloseAlert} />

          {inputValues && (
            <form className="pb-24 flex">
              <div className="mx-auto container w-[616px]">
                <Paper id="basic-info">
                  <div className="pl-6 pt-6 pb-[69px] pr-8">
                    <Title>{PRODUCT_FORM.BASIC_INFO}</Title>
                    <FormControl className="mt-6 w-full">
                      <InputLabel text={PRODUCT_FORM.NAME.LABEL} isRequired={true} />
                      <TextField
                        id={PRODUCT_FORM.NAME.ID}
                        placeholder="placeholder"
                        color="secondary"
                        fullWidth={true}
                        value={inputValues.name}
                        size="small"
                        onChange={handleOnChange}
                        focused
                      />
                    </FormControl>

                    <ImageUploader
                      imageUrl={imageUrl}
                      fileName={`${product?.id}.png`}
                      uploadPath={`roasters/${roaster?.id}/products`}
                      onUploaded={handleOnImageUploaded}
                    />

                    <FormControl className="mt-6">
                      <InputLabel text={PRODUCT_FORM.DESCRIPTION.LABEL} isRequired={true} />
                      <TextField
                        id={PRODUCT_FORM.DESCRIPTION.ID}
                        aria-label="Placeholder  text textarea"
                        placeholder="Placeholder  text"
                        style={{ height: 124, resize: 'none', width: 320 }}
                        value={inputValues.description}
                        multiline
                        rows={6}
                        onChange={handleOnChange}
                      />
                    </FormControl>

                    <FormControl className="mt-[62px]">
                      <InputLabel text={PRODUCT_FORM.AWARDS.LABEL} isRequired={true} />
                      <TextField
                        id={PRODUCT_FORM.AWARDS.ID}
                        multiline
                        rows={6}
                        value={inputValues.awards.join('\n')}
                        className="w-[320px]"
                        onChange={handleOnChange}
                      />
                    </FormControl>
                  </div>
                </Paper>

                <Paper id="product-attributes" className="mt-[10px]">
                  <div className="pl-6 pt-6 pb-[69px] pr-8">
                    <Title>{PRODUCT_FORM.PRODUCT_ATTRIBUTES}</Title>

                    <Subtitle>{PRODUCT_FORM.TASTE_NOTES.TITLES.PRIMARY}</Subtitle>
                    <FormControl className="w-full mt-4">
                      <InputLabel text={PRODUCT_FORM.TASTE_NOTES.LABEL} isRequired={true} />
                      <SimpleSelect
                        name={PRODUCT_FORM.TASTE_NOTES.NAME}
                        value={PRODUCT_FORM.TASTE_NOTES.OPTIONS[0]}
                        options={PRODUCT_FORM.TASTE_NOTES.OPTIONS}
                        onChange={() => {}}
                      />
                    </FormControl>

                    <Subtitle>{PRODUCT_FORM.TASTE_NOTES.TITLES.SECONDARY}</Subtitle>
                    <FormControl className="w-full mt-4">
                      <InputLabel text={PRODUCT_FORM.TASTE_NOTES.LABEL} isRequired={true} />
                      <MultiSelect
                        id={PRODUCT_FORM.TASTE_NOTES.ID}
                        options={PRODUCT_FORM.TASTE_NOTES.OPTIONS}
                        value={inputValues.tasteNotes}
                        limitTags={4}
                        onChange={(value) => setInputValues({ ...inputValues, tasteNotes: value })}
                      />
                    </FormControl>

                    <div className="mt-7">
                      <InputLabel text={PRODUCT_FORM.TASTE_DESC.LABEL} isRequired={true} />
                      <TextField
                        id={PRODUCT_FORM.TASTE_DESC.ID}
                        multiline
                        rows={6}
                        className="w-full mb-[60px]"
                        aria-label="Placeholder  text textarea"
                        placeholder="Placeholder  text"
                        value={inputValues.tasteDescription}
                        onChange={handleOnChange}
                      />

                      <Subtitle>{PRODUCT_FORM.ORIGINS.TITLE}</Subtitle>
                      <div className="mt-4">
                        {inputValues.origins.map(
                          ({ country, city }: { country: string; city: string }, index: number) => (
                            <div key={index} className="flex gap-4 mb-2">
                              <div className="flex-auto">
                                {index === 0 && (
                                  <InputLabel text={PRODUCT_FORM.ORIGINS.COUNTRY.LABEL} isRequired={true} />
                                )}
                                <TextField
                                  id={`origins.${index}.country`}
                                  name="origin.country"
                                  placeholder={PRODUCT_FORM.ORIGINS.COUNTRY.LABEL}
                                  fullWidth={true}
                                  required={true}
                                  size="small"
                                  value={country}
                                  onChange={handleOnChange}
                                />
                              </div>
                              <div className="flex-auto">
                                {index === 0 && <InputLabel text={PRODUCT_FORM.ORIGINS.CITY.LABEL} isRequired={true} />}
                                <TextField
                                  id={`origins.${index}.city`}
                                  name="origin.city"
                                  placeholder={PRODUCT_FORM.ORIGINS.CITY.LABEL}
                                  fullWidth={true}
                                  required={true}
                                  size="small"
                                  value={city}
                                  onChange={handleOnChange}
                                />
                              </div>
                              {inputValues.origins.length > 1 && (
                                <div className="flex-none flex">
                                  <Button
                                    variant="text"
                                    size="small"
                                    startIcon={<DeleteIcon />}
                                    className="mt-auto"
                                    onClick={() => handleOnRemoveEntry(PRODUCT_FORM.ORIGINS.ID, index)}
                                  >
                                    {PRODUCT_FORM.REMOVE_ENTRY}
                                  </Button>
                                </div>
                              )}
                            </div>
                          ),
                        )}
                        <Button
                          variant="text"
                          color="info"
                          className="text-blue-500"
                          onClick={() => handleOnAddEntry(PRODUCT_FORM.ORIGINS.ID, { city: '', country: '' })}
                        >
                          + {PRODUCT_FORM.ADD_ENTRY}
                        </Button>
                      </div>

                      <div className="flex justify-between mt-5">
                        <FormControl variant="standard" sx={{ m: 0, width: 272 }}>
                          <InputLabel text={PRODUCT_FORM.VARIETIES.LABEL} isRequired={true} />
                          <MultiSelect
                            id={PRODUCT_FORM.VARIETIES.ID}
                            options={PRODUCT_FORM.VARIETIES.OPTIONS}
                            value={inputValues.varieties}
                            limitTags={2}
                            onChange={(value) => setInputValues({ ...inputValues, varieties: value })}
                          />
                        </FormControl>
                        <InputWithLabel
                          id={PRODUCT_FORM.ALTITUDE_METERS.ID}
                          type="number"
                          label={PRODUCT_FORM.ALTITUDE_METERS.LABEL}
                          width="w-[272px]"
                          required={true}
                          value={inputValues.altitudeMeters}
                          onChange={handleOnChange}
                        />
                      </div>

                      <div className="flex justify-between mt-5">
                        <FormControl className="w-[272px]">
                          <InputLabel text={PRODUCT_FORM.ROAST_LEVEL.LABEL} isRequired={true} />
                          <SimpleSelect
                            name={PRODUCT_FORM.ROAST_LEVEL.NAME}
                            value={inputValues.roastLevel}
                            options={PRODUCT_FORM.ROAST_LEVEL.OPTIONS}
                            onChange={handleOnSelect}
                          />
                        </FormControl>
                        <FormControl className="w-[272px]">
                          <InputLabel text={PRODUCT_FORM.PROCESS.LABEL} isRequired={true} />
                          <SimpleSelect
                            name={PRODUCT_FORM.PROCESS.NAME}
                            value={inputValues.process}
                            options={PRODUCT_FORM.PROCESS.OPTIONS}
                            onChange={handleOnSelect}
                          />
                        </FormControl>
                      </div>

                      <FormControl className="w-full mt-5">
                        <InputLabel text={PRODUCT_FORM.BREW_METHODS.LABEL} isRequired={true} />
                        <MultiSelect
                          id={PRODUCT_FORM.BREW_METHODS.ID}
                          options={PRODUCT_FORM.BREW_METHODS.OPTIONS}
                          value={inputValues.brewMethods}
                          limitTags={4}
                          onChange={(value) => setInputValues({ ...inputValues, brewMethods: value })}
                        />
                      </FormControl>

                      <FormControl className="w-full mt-5">
                        <InputLabel text={PRODUCT_FORM.ADDITIONS.LABEL} isRequired={true} />
                        <MultiSelect
                          id={PRODUCT_FORM.ADDITIONS.ID}
                          options={PRODUCT_FORM.ADDITIONS.OPTIONS}
                          value={inputValues.additions}
                          limitTags={4}
                          onChange={(value) => setInputValues({ ...inputValues, additions: value })}
                        />
                      </FormControl>

                      <FormControl>
                        <RadioGroup
                          name={PRODUCT_FORM.GROUNDABLE.NAME}
                          value={inputValues.groundable}
                          onChange={handleOnChange}
                        >
                          <div className="flex pt-[22px]">
                            <FormControlLabel value={true} control={<Radio />} label="มีบริการบด" />
                            <FormControlLabel value={false} control={<Radio />} label="ไม่บด" />
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                </Paper>

                <Paper id="roasting-note" className="mt-[8px] px-6 pt-6 pb-9">
                  <div className="w-[272px]">
                    <Title>{PRODUCT_FORM.ROASTING_NOTE}</Title>

                    <FormControl fullWidth className="mt-5">
                      <InputLabel text={PRODUCT_FORM.TASTE_QUALITIES.ACIDITY.LABEL} isRequired={true} />
                      <SimpleSelect
                        name={PRODUCT_FORM.TASTE_QUALITIES.ACIDITY.NAME}
                        value={inputValues.tasteQualities.acidity}
                        options={PRODUCT_FORM.TASTE_QUALITIES.LEVEL_OPTIONS}
                        onChange={handleOnSelectTasteQualities}
                      />
                    </FormControl>

                    <FormControl fullWidth className="mt-5">
                      <InputLabel text={PRODUCT_FORM.TASTE_QUALITIES.SWEETNESS.LABEL} isRequired={true} />
                      <SimpleSelect
                        name={PRODUCT_FORM.TASTE_QUALITIES.SWEETNESS.NAME}
                        value={inputValues.tasteQualities.sweetness}
                        options={PRODUCT_FORM.TASTE_QUALITIES.LEVEL_OPTIONS}
                        onChange={handleOnSelectTasteQualities}
                      />
                    </FormControl>

                    <FormControl fullWidth className="mt-5">
                      <InputLabel text={PRODUCT_FORM.TASTE_QUALITIES.BODY.LABEL} isRequired={true} />
                      <SimpleSelect
                        name={PRODUCT_FORM.TASTE_QUALITIES.BODY.NAME}
                        value={inputValues.tasteQualities.body}
                        options={PRODUCT_FORM.TASTE_QUALITIES.LEVEL_OPTIONS}
                        onChange={handleOnSelectTasteQualities}
                      />
                    </FormControl>
                  </div>
                </Paper>

                <Paper id="selling-info" className="mt-[8px]">
                  <div className="pl-6 pt-6 pb-9 pr-8">
                    <Title>{PRODUCT_FORM.SELLING_INFO.TITLE}</Title>
                    <div className="mt-4">
                      {inputValues.quantityOptions.map(
                        ({ grams, stock, price }: { grams: number; stock: number; price: number }, index: number) => (
                          <div key={index} className="flex gap-4 mb-2">
                            <div className="flex-auto">
                              {index === 0 && (
                                <InputLabel
                                  text={PRODUCT_FORM.SELLING_INFO.QUANTITY_OPTIONS.SIZE.LABEL}
                                  isRequired={true}
                                />
                              )}
                              <TextField
                                id={`quantityOptions.${index}.grams`}
                                name="quantityOptions.grams"
                                type="number"
                                placeholder="0"
                                fullWidth={true}
                                required={true}
                                size="small"
                                value={grams}
                                onChange={handleOnChange}
                              />
                            </div>
                            <div className="flex-auto">
                              {index === 0 && (
                                <InputLabel
                                  text={PRODUCT_FORM.SELLING_INFO.QUANTITY_OPTIONS.STOCK.LABEL}
                                  isRequired={true}
                                />
                              )}
                              <TextField
                                id={`quantityOptions.${index}.stock`}
                                name="quantityOptions.stock"
                                type="number"
                                placeholder="0"
                                fullWidth={true}
                                required={true}
                                size="small"
                                value={stock}
                                onChange={handleOnChange}
                              />
                            </div>
                            <div className="flex-auto">
                              {index === 0 && (
                                <InputLabel
                                  text={PRODUCT_FORM.SELLING_INFO.QUANTITY_OPTIONS.PRICE.LABEL}
                                  isRequired={true}
                                />
                              )}
                              <TextField
                                id={`quantityOptions.${index}.price`}
                                name="quantityOptions.price"
                                type="number"
                                placeholder="0"
                                fullWidth={true}
                                required={true}
                                size="small"
                                value={price}
                                onChange={handleOnChange}
                              />
                            </div>
                            {inputValues.quantityOptions.length > 1 && (
                              <div className="flex-none flex">
                                <Button
                                  variant="text"
                                  size="small"
                                  startIcon={<DeleteIcon />}
                                  className="mt-auto"
                                  onClick={() =>
                                    handleOnRemoveEntry(PRODUCT_FORM.SELLING_INFO.QUANTITY_OPTIONS.ID, index)
                                  }
                                >
                                  {PRODUCT_FORM.REMOVE_ENTRY}
                                </Button>
                              </div>
                            )}
                          </div>
                        ),
                      )}
                      <Button
                        variant="text"
                        color="info"
                        className="text-blue-500"
                        onClick={() =>
                          handleOnAddEntry(PRODUCT_FORM.SELLING_INFO.QUANTITY_OPTIONS.ID, {
                            grams: 0,
                            price: 0,
                            stock: 0,
                          })
                        }
                      >
                        + {PRODUCT_FORM.ADD_OPTION}
                      </Button>
                    </div>
                  </div>
                </Paper>
              </div>
            </form>
          )}
        </div>

        <FormSidebar classNames="flex-1 h-screen sticky top-12 pl-8" menuItems={menuItems} />
      </div>
      <FormFooter onPublish={handleOnPublish} onSaveAsDraft={handleOnSaveAsdraft} onClose={handleOnClose} />
    </main>
  );
};

export default ProductForm;

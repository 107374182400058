/* eslint-disable max-lines-per-function */
import { useSnackbarController } from '@camberi/firecms';
import { DatePicker, DateRange, LoadingButton } from '@mui/lab';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  RadioProps,
  TextField,
  Typography,
} from '@mui/material';
import { FC, useState } from 'react';

import useOrderExport from '../../../hooks/useOrderExport';

const ExportPage: FC = () => {
  const snackbarController = useSnackbarController();
  const [startDate, setStartDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();
  const [exportType, setExportType] = useState<string>('all');
  const { exportOrders } = useOrderExport();
  const [exporting, setExporting] = useState(false);

  const handleExport = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setExporting(true);
    try {
      const data = await exportOrders(exportType === 'range' ? ([startDate, endDate] as DateRange<Date>) : undefined);
      console.info('CSV Data', data);
    } catch (error) {
      console.error('Order Export Error', error);
      snackbarController.open({ message: 'โปรดเลือกช่วงเวลาให้ถูกต้อง', type: 'error' });
    } finally {
      setExporting(false);
      snackbarController.open({ message: 'ดาวน์โหลดสำเร็จแล้ว', type: 'success' });
    }
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExportType((event.target as HTMLInputElement).value);
  };

  function DateRangeRadio(props: RadioProps) {
    return (
      <div className="pt-[26px] flex">
        <Radio {...props} />
        <div className="flex justify-center align-middle items-center">
          <div className="w-[216px] h-[40px] mb-4 mr-[9px]">
            <DatePicker
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
          <div className="font-bold text-xl">-</div>
          <div className="w-[216px] h-[40px] mb-4 ml-[9px]">
            <DatePicker
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={handleExport} className="pl-14 pt-12 pr-14 w-full">
      <Typography className="font-bold text-3xl">Export Data</Typography>
      <div className="pt-8 w-full">
        <Paper>
          <div className="pl-8 pt-8">
            <FormControl>
              <FormLabel className="flex items-center text-black">
                <Typography>Data Range</Typography> <div className="text-red-600">&nbsp;*</div>
              </FormLabel>
              <RadioGroup onChange={handleRadioChange} value={exportType} defaultValue="all" className="pt-[31px]">
                <FormControlLabel value="all" control={<Radio />} label="ทั้งหมด" />
                <FormControlLabel value="range" control={<DateRangeRadio />} label=""></FormControlLabel>
              </RadioGroup>
              <div className="pt-[124px] pb-[24px]">
                <LoadingButton
                  className="rounded-full text-white bg-[#0068F0] "
                  type="submit"
                  variant="outlined"
                  loading={exporting}
                >
                  นำเข้า ข้อมูล
                </LoadingButton>
              </div>
            </FormControl>
          </div>
        </Paper>
      </div>
    </form>
  );
};

export default ExportPage;

import { useSnackbarController } from '@camberi/firecms';
import { DateRange, DateRangePicker, LoadingButton } from '@mui/lab';
import {
  Backdrop,
  Box,
  Fade,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import React, { FC, useState } from 'react';

import useOrderExport from '../../hooks/useOrderExport';

interface Props {
  open?: boolean;
  onClose?: () => void;
}

const ExportOrderModal: FC<Props> = ({ open = false, onClose }) => {
  const snackbarController = useSnackbarController();
  const { exportOrders } = useOrderExport();
  const [range, setRange] = useState(false);
  const [dateRange, setDateRange] = React.useState<DateRange<Date>>([null, null]);
  const [exporting, setExporting] = useState(false);

  const handleExport = async () => {
    setExporting(true);
    try {
      const data = await exportOrders(range ? dateRange : undefined);

      console.info('CSV Data', data);
    } catch (error) {
      console.error('Order Export Error', error);
      snackbarController.open({ message: 'Error exporting orders.', type: 'error' });
    } finally {
      setExporting(false);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        open={open}
        onClose={onClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              display: 'flex',
              flexDirection: 'column',
              left: '50%',
              p: 4,
              position: 'absolute',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
            }}
          >
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Export Form
            </Typography>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Date Range</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                value={range}
                onChange={(event) => setRange(event.target.value === 'true')}
              >
                <FormControlLabel value="false" control={<Radio />} label="All Time" />
                <FormControlLabel value="true" control={<Radio />} label="Chose a date range" />
              </RadioGroup>
            </FormControl>
            {range && (
              <DateRangePicker
                startText="Start Date"
                endText="End Date"
                value={dateRange}
                onChange={(newValue) => {
                  setDateRange(newValue);
                }}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <TextField {...startProps} />
                    <Box sx={{ mx: 2 }}> to </Box>
                    <TextField {...endProps} />
                  </React.Fragment>
                )}
              />
            )}
            <LoadingButton onClick={handleExport} loading={exporting}>
              Export Orders
            </LoadingButton>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ExportOrderModal;

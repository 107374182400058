/* eslint-disable sort-keys-fix/sort-keys-fix */

import { buildEntityCallbacks, buildSchema, EntityReference } from '@camberi/firecms';
import { deleteDoc, doc, getFirestore, setDoc } from 'firebase/firestore';

import { Roaster } from './roaster.schema';
import { User } from './user.schema';

export enum EmploymentRole {
  'Admin' = 'Admin',
}

export interface Employment {
  userRef: EntityReference;
  role: EmploymentRole;
  roaster: {
    name: Roaster['name'];
    description: Roaster['description'];
    logo: Roaster['logo'];
    slug: Roaster['slug'];
  };
  user: {
    firstName: User['firstName'];
    lastName: User['lastName'];
    email: User['email'];
  };
}

export type EmploymentSchema = Omit<Employment, 'roaster' | 'user'>;

export const employmentSchema = buildSchema<EmploymentSchema>({
  name: 'Employment',
  defaultValues: { role: 'Admin' },
  properties: {
    userRef: { dataType: 'reference', path: 'users', title: 'User Reference', validation: { required: true } },
    role: {
      config: { enumValues: EmploymentRole },
      dataType: 'string',
      title: 'Role',
      validation: { required: true },
    },
  },
});

export const employmentCallbacks = buildEntityCallbacks<EmploymentSchema>({
  onPreSave: async ({ values, status, path }) => {
    const { userRef, role } = values;
    if (status !== 'existing' && userRef) {
      const db = getFirestore();
      const employmentDoc = doc(db, path, userRef.id);

      await setDoc(employmentDoc, { role, userRef: doc(db, 'users', userRef.id) });

      return { role, userRef, ignore: true };
    }

    return values;
  },
  onSaveSuccess: async ({ entityId, status, path }) => {
    if (status !== 'existing' && entityId) {
      const db = getFirestore();
      const employmentDoc = doc(db, path, entityId);

      await deleteDoc(employmentDoc);
    }
  },
});

import { Dispatch, SetStateAction, useEffect, useState } from 'react';

interface Result<T> {
  data?: T;
  error?: Error;
  loading: boolean;
  refetch?: () => void;
}

type AsyncEffect<T> = () => T | Promise<T>;

const run = async <T>(
  asyncEffect: AsyncEffect<T>,
  setState: Dispatch<SetStateAction<Result<T>>>,
  refetch: () => void,
) => {
  try {
    setState({
      data: await asyncEffect(),
      loading: false,
      refetch,
    });
  } catch (error) {
    setState({
      error: error as Error,
      loading: false,
      refetch,
    });
  }
};

const useAsyncEffect = <T>(asyncEffect: AsyncEffect<T>, dependencies: unknown[] = []): Result<T> => {
  const defaultState: Result<T> = { loading: true };
  const [timestamp, setTimestamp] = useState<number>();
  const [state, setState] = useState<Result<T>>(defaultState);

  useEffect(() => {
    setState({ loading: true });
    run<T>(asyncEffect, setState, () => setTimestamp(Date.now()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamp, ...dependencies]);

  return state;
};

export default useAsyncEffect;

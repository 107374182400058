import CloseIcon from '@mui/icons-material/Close';
import { Backdrop, Box, Button, Grid, Modal, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { FC, useEffect, useState } from 'react';

import { OrderDocument } from '../../../../interfaces/Firestore';
import { createFlashOrder, getFlashLabel } from '../../../../utils/flash';
import { ORDER_LABEL } from '../../constants/order';
import { OrderDetail } from './OrderList';

const dimensions = {
  height: 200,
  length: 100,
  weight: 300,
  width: 150,
};
interface Props {
  onOpen: boolean;
  onClose: (value: boolean) => void;
  order: OrderDocument | undefined;
  orderDetail: OrderDetail | undefined;
}
const style = {
  bgcolor: 'background.paper',
  boxShadow: 24,
  left: '50%',
  position: 'absolute' as const,
  px: 3,
  py: 6,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 616,
};

const OrderLabel: FC<Props> = ({ onOpen, onClose, order, orderDetail }) => {
  const [open] = useState<boolean>(onOpen);
  const [blob, setBlob] = useState<Blob>();
  const handleClose = () => onClose(false);
  useEffect(() => {
    if (orderDetail?.orderRoasterIndex !== undefined && order?.id) {
      createFlashOrder(order.id, {
        dimensions,
        sourceAddress: order?.shippingAddress,
        ticketIdx: orderDetail.orderRoasterIndex,
      }).finally(() =>
        getFlashLabel(order?.id || '', orderDetail.orderRoasterIndex)
          .then((blob) => {
            setBlob(blob);
            openPdf(blob);
          })
          .catch((error) => console.error(error)),
      );
    }
  }, [order, orderDetail?.orderRoasterIndex]);
  const openPdf = (blob: Blob) => {
    const iframe = document.querySelector('iframe');
    if (iframe) {
      iframe.src = URL.createObjectURL(blob);
    }
  };
  const downloadPdf = (blob: Blob | undefined) => {
    if (blob) {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'flash_label.pdf';
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropComponent={Backdrop}
    >
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 29,
            top: 29,
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <Typography id="modal-modal-title" fontSize="32px" fontWeight="bold">
          {ORDER_LABEL.TITLE}
        </Typography>
        <Grid sx={{ display: 'flex', marginTop: '48px' }}>
          <Typography id="modal-modal-description" marginRight="15px">
            {ORDER_LABEL.LABEL}
          </Typography>
          <Typography fontWeight="bold" marginRight="6px">
            {order?.shippingAddress?.firstName}
          </Typography>
          <Typography fontWeight="bold">{order?.shippingAddress?.lastName}</Typography>
        </Grid>
        <Grid sx={{ display: 'flex', marginTop: '16px' }}>
          <Typography id="modal-modal-description" marginRight="46px">
            {ORDER_LABEL.ORDER_ID}:
          </Typography>
          <Typography fontWeight="bold">{order?.id}</Typography>
        </Grid>
        <Grid sx={{ display: 'flex', height: '232px', marginBottom: '28px', marginTop: '16px' }}>
          <iframe id="flash-label" style={{ height: 'inherit', position: 'absolute', right: '155px' }}></iframe>
        </Grid>
        <Button
          className="bg-blue-500 font-normal text-base rounded-full normal-case no-underline"
          variant="contained"
          color="info"
          sx={{
            borderRadius: 9999,
          }}
          onClick={() => downloadPdf(blob)}
        >
          <Typography>{ORDER_LABEL.DOWNLOAD}</Typography>
        </Button>
      </Box>
    </Modal>
  );
};

export default OrderLabel;

import {
  DocumentData,
  getDocs,
  limit,
  Query,
  query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  startAfter,
} from 'firebase/firestore';

const LIMIT = 200;

const getAllDocs = async <T = DocumentData>(docsQuery: Query<T>): Promise<QueryDocumentSnapshot<T>[]> => {
  const allDocs: QueryDocumentSnapshot<T>[] = [];
  const fetchQuery = query(docsQuery, limit(LIMIT)) as Query<T>;
  let snapshots = (await getDocs(fetchQuery)) as QuerySnapshot<T>;

  snapshots.forEach((doc) => allDocs.push(doc));

  if (snapshots.size < LIMIT) return allDocs;

  let lastDoc = snapshots.docs[snapshots.docs.length - 1];

  do {
    snapshots = await getDocs(query(fetchQuery, startAfter(lastDoc)));
    snapshots.forEach((doc) => allDocs.push(doc));
    lastDoc = snapshots.docs[snapshots.docs.length - 1];
  } while (snapshots.size >= LIMIT);

  return allDocs;
};

export default getAllDocs;
